<ul class="process-funnel row">
  <li class="col-6" [ngClass]="{active: activeStep === 0}">
    <span class="step-text hidden-xs">
      {{firstStepName}}
    </span>
  </li>
  <li class="col-6" [ngClass]="{active: activeStep === 1}">
    <span class="step-text hidden-xs">
      {{secondStepName}}
    </span>
  </li>
</ul>
