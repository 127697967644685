import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {isPlatformServer} from '@angular/common';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransferStateService {
  private keys = new Map<string, StateKey<string>>();

  constructor(@Inject(PLATFORM_ID) private readonly platformId: string,
              private readonly transferState: TransferState) {
  }

  public fetch<T>(key: string, observableInput: Observable<T>, defaultValue?: T): Observable<T> {
    if (this.has(key)) {
      // @ts-ignore
      return of(this.get(key, defaultValue)).pipe(tap(() => this.remove(key)));
    } else {
      return observableInput.pipe(tap((value) => this.set(key, value)));
    }
  }

  public get<T>(key: string, defaultValue?: T | null): T | null {
    if (!this.has(key)) {
      return defaultValue || null;
    }

    const cacheValue = this.transferState.get<string | null>(this.getStateKey(key), null);

    return cacheValue ? this.deserializeCacheValue(cacheValue as string) : null;
  }

  public has(key: string): boolean {
    return this.transferState.hasKey(this.getStateKey(key));
  }

  public remove(key: string): void {
    if (this.has(key)) {
      this.transferState.remove(this.getStateKey(key));
    }
  }

  public set<T>(key: string, value: T): void {
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(this.getStateKey(key), this.serializeCacheValue(value));
    }
  }

  public deserializeCacheValue(cacheValue: string) {
    const parsedCacheValue: { value: any } = JSON.parse(cacheValue);
    return parsedCacheValue.value;
  }

  private serializeCacheValue(value: any): string {
    return JSON.stringify({value});
  }

  private getStateKey(key: string): StateKey<string> {
    if (!this.keys.has(key)) {
      this.keys.set(key, makeStateKey(key));
    }
    // @ts-ignore
    return this.keys.get(key);
  }
}
