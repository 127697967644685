export function replaceAll(data: string, searchTerm: string | RegExp, replaceValue: string): string {
  if (searchTerm === replaceValue) {
    return data;
  }

  if (searchTerm instanceof RegExp) {
    let match;

    // tslint:disable-next-line:no-conditional-assignment
    while ((match = searchTerm.exec(data))) {
      data = data.replace(searchTerm, replaceValue);
    }

  } else {
    while (data.includes(searchTerm)) {
      data = data.replace(searchTerm, replaceValue);
    }
  }

  return data;
}
