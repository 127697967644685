import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {CookieConsentMode} from '../../../../entities/cookie-consent/cookie-consent-mode.enum';
import {isPlatformBrowser} from '@angular/common';
import {ConsentService} from '../../../../services/consent.service';
import {MessageBoxService} from '../../../../services/message-box.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent-banner.component.html',
  styleUrls: ['./cookie-consent-banner.component.scss']
})
export class CookieConsentBannerComponent implements OnInit {
  public isActive = false;

  constructor(private readonly consentService: ConsentService,
              private readonly messageBoxService: MessageBoxService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
  }

  ngOnInit(): void {
    this.consentService.setMessageBoxService(this.messageBoxService);
    this.consentService.getConsentMode().subscribe((mode: number) => this.isActive = mode === CookieConsentMode.SIMPLE && isPlatformBrowser(this.platformId));
  }

  public acceptAll(): void {
    this.consentService.consentAll();
  }

  public changeSettings(): void {
    this.consentService.changeSettings();
  }
}
