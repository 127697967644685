import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {EMPTY, Observable, of, ReplaySubject, tap} from 'rxjs';
import {SessionService} from './session.service';
import {isPlatformBrowser} from '@angular/common';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Routes} from '../enums/routes';
import {LoginService, LoginState} from '@kpro-software/users-lib';

@Injectable({
  providedIn: 'root'
})
// tslint:disable:no-bitwise
export class LoginStateService {
  private loginState: LoginState | null = null;
  private subject = new ReplaySubject<LoginState | null>(1);

  constructor(private readonly cookieService: SessionService,
              private readonly loginService: LoginService,
              protected readonly router: Router,
              @Inject(PLATFORM_ID) private platformId: string) {
  }

  public pushLoginState(loginState: LoginState | null) {
    this.loginState = loginState;
    this.subject.next(loginState);
    this.cookieService.loginState = loginState;
  }

  public getLoginState(): Observable<LoginState | null> {
    return this.subject.asObservable();
  }

  public initialize() {
    if (isPlatformBrowser(this.platformId)) {
      return this.loadLoginState();

    } else {
      this.pushLoginState(null);
    }
    return EMPTY;
  }

  public refreshLoginState() {
    this.loadLoginState().subscribe();
  }

  public loadLoginState(): Observable<LoginState | null> {
    let loginState: LoginState | null = null;

    const accessToken = this.cookieService.accessToken;
    const tokenType = this.cookieService.tokenType;

    if (accessToken) {
      // Login-State laden
      return this.loadLoginStateFromUserService(accessToken, tokenType)
        .pipe(
          catchError(() => of(loginState)),
          tap((loginState) => this.pushLoginState(loginState))
        );

    } else {
      return of(loginState);
    }
  }

  public logout(): void {
    this.cookieService.removeCookies();
    this.pushLoginState(null);
    this.router.navigateByUrl(Routes.LOGIN);
  }

  private loadLoginStateFromUserService(accessToken: string, tokenType: string): Observable<LoginState> {
    // @ts-ignore
    return this.loginService.getLoginState(accessToken, tokenType);
  }
}
