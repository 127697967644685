import {Injectable} from '@angular/core';
import {LocaleService} from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(private readonly localeService: LocaleService) {
  }

  public getPedigreeLink(horseId: number) {
    return this.localeService.getPedigreeUrl() + 'horses/pedigree/' + horseId;
  }
}
