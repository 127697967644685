export enum SimplePages {
  CONTACT = 'contact',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  INBREEDING = 'inbreeding',
  ABOUT_US = 'about-us',
  HELP_AND_INFO = 'help',
  COOKIES = 'cookies',
  PRIVACY_STATEMENT = 'privacy-statement',
  DISCLAIMER = 'disclaimer',
  BANNER_PRICES = 'banner-prices',
  SUBSCRIPTIONS = 'subscriptions',
  LEGAL_NOTICE = 'legal-notice',
  ISV_AND_IPV = 'ipvIntroduction'
}
