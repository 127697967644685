import {Component, Input, OnInit} from '@angular/core';
import {AdsService} from '../../../../services/ads/ads.service';
import {AdEventService} from '../../../../services/ads/ad-event.service';
import {AdEventMetaData} from '../../../../entities/ads/ad-event-meta-data';
import {VisualAd} from '@kpro-software/horsetelex-ads';

@Component({
  selector: 'app-ad-banner-container',
  templateUrl: './ad-banner-container.component.html',
  styleUrls: ['./ad-banner-container.component.scss']
})
export class AdBannerContainerComponent implements OnInit {

  @Input()
  public id: string = '';

  @Input()
  public interval = 5000; // Gibt an wie oft das Bild geändert wird (in ms)

  public visuals: Array<VisualAd> = [];

  constructor(private readonly adsService: AdsService,
              private readonly adEventService: AdEventService) {
  }

  ngOnInit() {
    this.adsService.getActiveVisualAds(this.id).subscribe(value => {
      this.visuals = value;
    });
  }

  public emitClickEvent(metaData: object) {
    this.adEventService.emitAdClickEvent(metaData).subscribe();
  }

  public emitImpressionEvent(metaData: object) {
    if (this.adEventService.canSendImpression() && metaData) {
      this.adEventService.emitAdImpressionEvent(metaData).subscribe();
    }
  }

  public getMetaData: () => AdEventMetaData = () => {
    return this.adEventService.getMetaData() as AdEventMetaData;
  };
}
