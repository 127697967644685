<div class="dropdown" ngbDropdown #dropdown>
  <button class="form-control pe-1 w-100 d-flex justify-content-between align-items-center" ngbDropdownToggle
          #menuToggle (click)="toggleMenuState()">
    <span class="selected-items" *ngIf="selectedOptions.length > 0" #selectedItems [ngbTooltip]="getSelectedNames()">
      {{getSelectedNames()}}
    </span>
    <span *ngIf="selectedOptions.length === 0">{{placeholder}}</span>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <div class="dropdown-option" *ngFor="let option of options" (click)="toggleOption(option)" ngbDropdownItem>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [ngModel]="isSelected(getId(option))">
        <label class="form-check-label">
          {{getName(option)}}
        </label>
      </div>
    </div>
  </div>
</div>
