// Die Routen für 'CALENDAR', 'RESULTS', 'RANKINGS' und 'ISV_IPV' wurden vorerst von der aktuellen Seite von Results übernommen.
export enum Routes {
  VALIDATE_EMAIL = '/user/validate-email',
  SIMPLE_PAGE = '/pages/view/',
  HOME = '/home',
  CALENDAR = '/calendar',
  RESULTS = '/tourneys',
  RANKINGS = '/rankings/index',
  ISV_IPV = '/ipv-rankings',
  ALERTS = '/alerts',
  ALERTS_RESULTS = '/alerts/my-results',
  ALERTS_RESULTS_ARCHIVE = '/alerts/my-results/archive',
  ALERTS_CREATE = '/alerts/create',
  SUBSCRIBE = '/subscribe',
  RANKINGS_DETAIL = '/rankings/', // + :horseId
  PEDIGREE = 'horses/pedigree/',
  ISV_HORSE_RESULTS = '/ipv-rankings/isv-horse-results/', // + :horseId/:sportType/:startDate/:endDate
  ISV_RIDER_RESULTS = '/ipv-rankings/isv-rider-results/', // + :horseId/:sportType/:startDate/:endDate
  IPV_CHILDREN = '/ipv-rankings/ipv-children/', // + :horseId/:sportType/:startDate/:endDate/:minAge/:sort/:categories
  IPV_GRANDCHILDREN = '/ipv-rankings/ipv-grandchildren/', // + :horseId/:sportType/:startDate/:endDate/:minAge/:sort/:categories
  IPV_STUDBOOK_CHILDREN = '/ipv-rankings/ipv-studbook-children/', // + :studbookId/:sportType/:startDate/:endDate/:minAge/:sort/:categories
  IPV_INTRODUCTION = '/pages/view/ipvIntroduction',
  LOGIN = '/sign-in',
  TOURNEYS = '/tourneys',
  TOURNEY = '/tourneys/', // + :tourneyId
  COMPETITION = '/competitions/', // + :competitionId
  USER_DATA = '/user/account',
  CHANGE_PASSWORD = '/user/change-password',
  TERMS_AND_CONDITIONS = '/',
  PRIVACY_STATEMENT = '/',
  MEMBERSHIPS = '/pages/view/subscriptions',
  PAYWALL = '/paywall/', // + :paywallKey
  MANAGE_SUBSCRIPTION = '/user/manage-subscription',
}
