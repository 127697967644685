import {Pipe, PipeTransform} from '@angular/core';
import {PriceResult} from '../../../data/responses/price-result';
import {Currencies} from '../../../enums/currencies';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'prize'
})
export class PrizePipe implements PipeTransform {

  constructor(private readonly currencyPipe: CurrencyPipe) {
  }

  transform(value: number | PriceResult, currency: Currencies | null = null): unknown {
    currency = currency === null ? Currencies.EUR : currency;
    let prize;

    if (typeof value === 'object') {
      prize = this.getPrize(value, currency);
    } else {
      prize = value;
    }
    return this.currencyPipe.transform(prize, currency.valueOf(), 'symbol', '.0-0');
  }

  private getPrize(result: PriceResult, currency: Currencies | null) {
    switch (currency) {
      case Currencies.AUD: {
        return result.currencyaud;
      }
      case Currencies.CAD: {
        return result.currencycad;
      }
      case Currencies.CHF: {
        return result.currencychf;
      }
      case Currencies.GBP: {
        return result.currencygbp;
      }
      case Currencies.USD: {
        return result.currencyusd;
      }
      default: {
        return result.currencyeur;
      }
    }
  }
}
