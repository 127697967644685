import {Pipe, PipeTransform} from '@angular/core';
import {IpvDataGrouping} from '../../../../enums/ipv/ipv-data-grouping';

@Pipe({
  name: 'ipvDomain'
})
export class IpvDomainPipe implements PipeTransform {
  transform(domain: string): string {
    switch (domain) {
      case IpvDataGrouping.STALLIONS:
        return $localize`:@@ipvDomainStallions:Hengste`;
      case IpvDataGrouping.DAMS:
        return $localize`:@@ipvDomainDams:Stuten`;
      case IpvDataGrouping.STUDBOOKS:
        return $localize`:@@ipvDomainStudbooks:Stammbücher`;
      default:
        return domain;
    }
  }
}
