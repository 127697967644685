import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enumValuesToArray'
})
export class EnumValuesToArrayPipe implements PipeTransform {
  transform(enumeration: object): string[] {
    return Object.values(enumeration);
  }
}
