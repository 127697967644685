import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input()
  public page!: number;

  @Input()
  public itemsPerPage!: number;

  @Input()
  public totalItems!: number;

  @Input()
  public disabled = false;

  @Input()
  public hideCount = false;

  @Input()
  public flexAlignment = 'justify-content-center';

  @Input()
  public maxPage: number | null = null;

  @Output()
  public changePage = new EventEmitter();

  @Input()
  public exceedsMaxItems = false;

  public lastPage!: number;

  constructor() {
  }

  ngOnInit(): void {
    this.lastPage = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  public change(page: number) {
    if (!this.disabled) {
      this.changePage.emit(page);
    }
  }

  public getTotalItems() {
    const totalItems = this.totalItems ?? 1;

    if (this.maxPage !== null) {
      const maxItems = this.maxPage * this.itemsPerPage;

      if (maxItems < totalItems) {
        return maxItems;
      }
    }

    return totalItems;
  }
}
