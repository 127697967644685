<div class="card card-body bg-light">
  <div class="mb-2" i18n="register | fill in your data and you will receive a login code by email. register@@FillData">
    Geben Sie Ihre Daten ein und Sie werden ein Login Passwort per E-Mail erhalten.
  </div>
  <lib-register-form [countries]="countries"
                     [focus]="focus"
                     [targetService]="targetService"
                     [termsAndConditionsUrl]="Routes.SIMPLE_PAGE + SimplePages.TERMS_AND_CONDITIONS"
                     (onError)="onRegisterError($event)"
                     (onRegister)="onRegister($event)"></lib-register-form>
</div>
