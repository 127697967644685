import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormComponent} from '../../../../util/form-component';
import {ValidationService} from '../../../../services/validation.service';
import {FormBuilder, Validators} from '@angular/forms';
import {fullTextValidator} from '../../../../validators/full-text-validator';
import {HorseSearchRequest} from '../../../../data/requests/pedigree/horse-search-request';
import {PedigreeStudbooksService} from '../../../../services/pedigree/pedigree-studbooks.service';

@Component({
  selector: 'app-horse-search-form',
  templateUrl: './horse-search-form.component.html',
  styleUrls: ['./horse-search-form.component.scss']
})
export class HorseSearchFormComponent extends FormComponent implements OnInit {
  @Input()
  public colClass = 'col-4';

  @Input()
  public resetColClass = 'col-4 offset-4';

  @Input()
  public submitColClass = 'col-4';

  @Input()
  public size: 'sm' | 'md' = 'md';

  @Output()
  public formChanged: EventEmitter<HorseSearchRequest> = new EventEmitter();

  public formControlClass = 'form-control';

  constructor(private fb: FormBuilder,
              protected override readonly validationService: ValidationService,
              public readonly studbookService: PedigreeStudbooksService) {
    super(validationService);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, fullTextValidator(2)],
      father: [null, fullTextValidator(2)],
      mother: [null, fullTextValidator(2)],
      fatherOfMother: [null, fullTextValidator(2)],
      year: [null],
      studbook: [null],
      reg: [null, fullTextValidator(3)],
      fei: [null, Validators.minLength(3)],
      chipnumber: [null, [fullTextValidator(3), Validators.maxLength(20)]]
    });

    if (this.size === 'sm') {
      this.formControlClass += ' form-control-sm'
    }
  }

  private getSearchRequest(): HorseSearchRequest {
    return this.form.value as HorseSearchRequest;
  }

  public resetForm(): void {
    this.form.reset();
  }

  public search(): void {
    if (this.form.valid && !this.isFormEmpty()) {
      this.formChanged.emit(this.getSearchRequest());
    } else {
      this.form.markAllAsTouched();
    }
  }

  public isFormEmpty(): boolean {
    for (const key of Object.keys(this.form.value)) {
      const control = this.form.get(key);
      if (control?.value && control.valid) {
        return false;
      }
    }
    return true;
  }
}
