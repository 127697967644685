<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('CROSS')"></button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="applyChoice(true)" i18n="confirm | confirm confirm@@ok">
    OK
  </button>
</div>
