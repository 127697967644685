import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {ValidationPatterns} from "../enums/validation-patterns.enum";
import {IpvDataGroupingTranslations} from '../constants/ipv-data-grouping-translations';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  public getErrorMessages(errors: ValidationErrors | null | undefined): Array<string> {
    const errorMessages = new Array<string>();
    if (errors) {
      Object.keys(errors).forEach(validator => {
        switch (validator) {
          case 'required':
            errorMessages.push($localize`:@@tsFieldNotEmpty:Dieses Feld darf nicht leer sein.`);
            break;
          case 'maxlength':
            errorMessages.push($localize`:@@tsValidationMaxLength:Dieses Feld darf maximal ${errors[validator].requiredLength} Zeichen enthalten. Aktuell: ${errors[validator].actualLength}.`);
            break;
          case 'minlength':
            errorMessages.push($localize`:@@tsValidationMinLength:Dieses Feld muss mindestens ${errors[validator].requiredLength} Zeichen enthalten. Aktuell: ${errors[validator].actualLength}.`);
            break;
          case 'pattern':
            if (errors[validator].requiredPattern === ValidationPatterns.INTEGER) {
              errorMessages.push($localize`:@@tsValidationInteger:Dieses Feld darf nur ganze zahlen enthalten. Aktuell: ${errors[validator].actualValue}.`);
            } else if (errors[validator].requiredPattern === ValidationPatterns.NUMBER) {
              errorMessages.push($localize`:@@tsValidationNumeric:Dieses Feld darf nur zahlen enthalten. Aktuell: ${errors[validator].actualValue}.`);
            } else if (errors[validator].requiredPattern === ValidationPatterns.BOOLEAN) {
              errorMessages.push($localize`:@@tsValidationBoolean:Dieses Feld darf nur 1 oder 0 bzw. true oder false enthalten.`);
            } else if (errors[validator].requiredPattern === ValidationPatterns.LETTERS_AND_NUMBERS){
              errorMessages.push($localize`:@@tsValidationNoSpecialCharacters:Dieses Feld darf nur Buchstaben und Zahlen enthalten.`);
            }
            break;
          case 'incorrect':
            errorMessages.push($localize`:@@tsValidationIncorrect:Dieses Feld enthält einen Fehler.`);
            break;
          case 'email':
            errorMessages.push($localize`:@@tsValidationEmail:Dieses Feld muss eine E-Mail-Adresse beinhalten.`);
            break;
          case 'invalidAsync':
            errorMessages.push($localize`:@@tsValidationEmailAlreadyAssigned:Diese E-Mail-Adresse ist bereits vergeben.`);
            break;
          case 'max':
            errorMessages.push($localize`:@@tsValidationMax:Der Inhalt dieses Felds hat ein Maximum von ${errors[validator].max}`);
            break;
          case 'min':
            errorMessages.push($localize`:@@tsValidationMin:Der Inhalt dieses Felds hat ein Minimum von ${errors[validator].min}`);
            break;
          default:
            console.error(validator + ' Nicht in ValidationService.getErrorMessage() definiert.');
            errorMessages.push($localize`:@@tsValidationDefault:Dieses Feld enthält einen Fehler.`);
            break;
        }
      });
    }
    return errorMessages;
  }

  showInvalid(control: AbstractControl | null): boolean {
    if (control) {
      return control.touched && control.invalid;
    }
    return false;
  }
}
