import {Component, Input, OnInit} from '@angular/core';
import {
  CookieConsentCategoryDescription
} from '../../../../entities/cookie-consent/cookie-consent-category-description';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CookieConsentCategory} from '../../../../entities/cookie-consent/cookie-consent-category.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieConsent} from '../../../../entities/cookie-consent/cookie-consent';

@Component({
  selector: 'app-extended-cookie-consent-modal',
  templateUrl: './extended-cookie-consent-modal.component.html',
  styleUrls: ['./extended-cookie-consent-modal.component.scss']
})
export class ExtendedCookieConsentModalComponent implements OnInit {
  @Input()
  public categories!: CookieConsentCategoryDescription[];

  @Input()
  public isInitialized = false;

  @Input()
  private consents!: { [name: string]: CookieConsent };

  public form!: FormGroup;
  public CookieConsentCategory = CookieConsentCategory;

  constructor(private fb: FormBuilder,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({});

    this.categories.forEach(category => {
      this.form.addControl(CookieConsentCategory[category.category], this.fb.control(this.isInitialized ? this.isCategorySet(category.category) : true));
    });
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public save(): void {
    const states: { [category: string]: boolean } = {};
    this.categories.forEach(category => {
      const name = CookieConsentCategory[category.category];
      states[name] = this.form.value[name];
    });

    this.activeModal.close(states);
  }

  private isCategorySet(category: CookieConsentCategory): boolean {
    let isSet = true;
    const consents = this.consents;

    Object.keys(consents).forEach(key => {
      const consent = consents[key];

      if (consent.category === category && !consent.isAccepted) {
        isSet = false;
      }

    });

    return isSet;
  }
}
