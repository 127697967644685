import {Component, ElementRef, forwardRef, HostListener, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true,
};

@Component({
  selector: 'app-multi-select',
  providers: [CUSTOM_VALUE_ACCESSOR, NgbDropdownConfig],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input()
  public options: any[] = [];

  @Input()
  public labelField!: string;

  @Input()
  public idField!: string;

  @Input()
  public placeholder!: string;

  @ViewChild('menuToggle')
  private menuToggle!: ElementRef;

  @ViewChild('dropdown')
  private dropdown!: ElementRef;

  public touched = false;
  public disabled = false;
  public selectedOptions: any[] = [];

  // tslint:disable-next-line:ban-types
  private onTouched: Function;
  // tslint:disable-next-line:ban-types
  private onChange: Function;
  private isMenuOpen = false;

  constructor(config: NgbDropdownConfig) {
    config.autoClose = false;

    this.onTouched = (flag: boolean) => {
    };
    this.onChange = (value: any) => {
    };
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.dropdown.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeMenu();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.selectedOptions = obj ? obj : [];
  }

  public toggleOption(option: any) {
    const id = this.getId(option);

    if (this.isSelected(id)) {
      this.unSelect(id);
    } else {
      this.select(id);
    }
  }

  public getId(option: any) {
    return this.idField ? option[this.idField] : option;
  }

  public getName(option: any) {
    return this.labelField ? option[this.labelField] : option;
  }

  public getNameById(id: any) {
    let option;

    if (this.idField) {
      option = this.options.find(item => item[this.idField] === id);
    } else {
      option = this.options.find(item => item === id);
    }

    if (option) {
      return this.getName(option);
    }
    return null;
  }

  public getSelectedNames() {
    const names: string[] = [];

    this.selectedOptions.forEach(id => {
      const name = this.getNameById(id);
      if (name) {
        names.push(name);
      }
    });

    return names.join(', ');
  }

  public isSelected(id: any): boolean {
    return this.selectedOptions.includes(id);
  }

  public selectAll() {
    const selectedOptions: any[] = [];
    this.options.forEach(option => {
      selectedOptions.push(this.getId(option));
    });

    this.selectedOptions = selectedOptions;
    this.commitValue();
  }

  public unselectAll() {
    this.selectedOptions = [];
    this.commitValue();
  }

  public unSelect(id: any) {
    this.selectedOptions.splice(this.selectedOptions.indexOf(id), 1);
    this.commitValue();
  }

  public toggleMenuState() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  private select(id: any) {
    this.selectedOptions.push(id);
    this.commitValue();
  }

  private commitValue() {
    this.onChange(this.selectedOptions);
  }

  private closeMenu() {
    if (this.isMenuOpen) {
      this.menuToggle.nativeElement.click();
    }
  }
}
