import {Pipe, PipeTransform} from '@angular/core';
import {HORSE_SEXES} from '../../../constants/HorseSexes';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(sex: number): string {
    return HORSE_SEXES[sex] ?? null;
  }

}
