import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginStateService} from '../../../../services/login-state.service';
import {Subscription} from 'rxjs';
import {TabTitleService} from '../../../../services/tab-title.service';
import {Routes} from '../../../../enums/routes';
import {RedirectService} from '../../../../services/redirect.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  @Input()
  public redirectUrl?: Routes;

  @Input()
  public displayResetPasswordForm = true;

  public isLogout = false;
  public fragment: string | null = null;

  private subscriptions: Subscription[] = [];

  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly loginState: LoginStateService,
              private readonly redirectService: RedirectService,
              private readonly tabTitleService: TabTitleService) {
  }

  ngOnInit(): void {
    this.tabTitleService.setTitle($localize`:@@tabTitleSignIn:Sign in`);

    this.loginState.getLoginState()
      .subscribe(loginState => {
        if (loginState) {

          if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
          } else {
            this.redirectService.redirect();
          }

        } else {
          this.isLogout = true;
        }
      });

    this.subscriptions.push(
      this.route.fragment.subscribe((fragment: string | null) => this.fragment = fragment)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
