import {ApiClient} from './api/api-client';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionService} from './session.service';
import {Router} from '@angular/router';
import {LocaleService} from './locale.service';
import {TransferStateService} from './transfer-state.service';

export abstract class AccessTokenApiClient extends ApiClient {
  protected constructor(protected override endpoint: string,
                        protected cookie: SessionService,
                        protected override http: HttpClient,
                        protected override router: Router,
                        protected override localeService: LocaleService,
                        protected override transferStateService: TransferStateService) {
    super(endpoint, http, router, localeService, transferStateService);
  }


  protected override getHttpOptions(params: {} = {}) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Locale: this.localeService.localeId
      }),
      params: this.createParams(params)
    };

    const accessToken = this.cookie.accessToken;

    if (accessToken) {
      const tokenType = this.cookie.tokenType;
      httpOptions.headers = httpOptions.headers.append('Authorization', tokenType + ' ' + accessToken);
    }

    return httpOptions;
  }
}
