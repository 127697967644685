import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, NEVER, Observable, of} from 'rxjs';
import {CookieConsentMode} from '../entities/cookie-consent/cookie-consent-mode.enum';
import {CookieConsent} from '../entities/cookie-consent/cookie-consent';
import {CookieConsentCategoryDescription} from '../entities/cookie-consent/cookie-consent-category-description';
import {switchMap} from 'rxjs/operators';
import {CookieConsentType} from '../entities/cookie-consent/cookie-consent-type.enum';
import {SessionService} from './session.service';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';
import {COOKIE_CONSENTS} from '../constants/cookie-consents';
import {MessageBoxService} from './message-box.service';
import {CookieConsentCategory} from '../entities/cookie-consent/cookie-consent-category.enum';
import {
  SingleCookieConsentModalComponent
} from '../layouts/ui/cookie-consent/single-cookie-consent-modal/single-cookie-consent-modal.component';
import {
  ExtendedCookieConsentModalComponent
} from '../layouts/ui/cookie-consent/extended-cookie-consent-modal/extended-cookie-consent-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  private consentMode: BehaviorSubject<CookieConsentMode> = new BehaviorSubject<CookieConsentMode>(CookieConsentMode.NONE);
  private categories: CookieConsentCategoryDescription[] = COOKIE_CONSENTS.categories;
  private consents: { [name: string]: CookieConsent } = {};
  private messageBoxService!: MessageBoxService;
  private isInitialized = false;

  constructor(private readonly session: SessionService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
  }

  public initialize() {
    // Gespeicherte CookieConents laden
    const consents = this.session.cookieConsent;

    // Prüfen ob sich die Keys der Konfiguration geändert hat
    if (consents && !this.hasConfigChanged(consents)) {
      this.consents = consents;
      this.isInitialized = true;

    } else {
      this.consents = COOKIE_CONSENTS.consents;
      this.show();
    }

    // Bereits akzeptierte Skripte ausführen;
    const scripts: CookieConsent[] = [];

    Object.keys(this.consents).forEach(name => {
      const consent = this.consents[name];
      if (consent.type === CookieConsentType.SCRIPT_CONSENT && consent.isAccepted) {
        scripts.push(consent);
      }
    });
    this.runScripts(scripts);
  }

  public getConsentMode(): BehaviorSubject<CookieConsentMode> {
    return this.consentMode;
  }

  /**
   * Öffnet den Cookie-Banner
   */
  public show() {
    this.consentMode.next(CookieConsentMode.SIMPLE);
  }

  /**
   * Öffnet den erweiterten Cookie-Consent-Modus
   */
  public changeSettings() {
    this.consentMode.next(CookieConsentMode.EXTENDED);
    this.messageBoxService.modal(
      ExtendedCookieConsentModalComponent,
      {
        categories: this.categories,
        isInitialized: this.isInitialized,
        consents: this.consents
      },
      {size: 'lg', centered: true}
    ).result.subscribe((states: { [category: string]: boolean }) => {
        this.setCategoryStates(states);
        this.close();
      },
      () => {
        if (!this.isInitialized) {
          this.show();
        }
      });
  }

  /**
   * Akzpetiert alle Cookies
   */
  public consentAll() {
    Object.keys(this.consents).forEach(name => {
      this.consent(name);
    });
    this.close();
  }

  /**
   * Prüft ob die Berechtigung für ein Cookie-Consent existiert.
   * Öffnet ein Modal zur erteilung der Berechtigung, wenn nicht vorhanden.
   * Gibt ein Observable zurück, welches NEVER enthält, wenn die Berechtigung verweigert wurde.
   * @param consentName Name des Cookie-Consents (Definiert im Enum CookieConsents und COOKIE_CONSENTS.consents)
   */
  public ensureFeatureConsent(consentName: string): Observable<any> {
    const consent = this.consents[consentName];

    // Prüfen ob ein ensprechnder Cookie-Consent konfiguriert ist
    if (!consent) {
      console.error('no consent with name ' + consentName + ' found');
      return of(NEVER);

      // Prüfen ob der Cookie-Consent akzeptiert wurde
    } else if (consent.isAccepted) {
      return of(true);

      // Single-Consent-Modus öffnen, wenn der Cookie-Consent nicht akzeptiert ist
    } else {
      this.consentMode.next(CookieConsentMode.SINGLE_CONSENT);

      return this.messageBoxService.modal(
        SingleCookieConsentModalComponent,
        {consent}
      ).result
        .pipe(switchMap(value => {

            if (value) {
              this.consents[consentName].isAccepted = true;
              this.close();
              return of(true);

            } else {
              this.close();
              return NEVER;
            }
          })
        );
    }
  }

  public setMessageBoxService(messageBoxService: MessageBoxService) {
    this.messageBoxService = messageBoxService;
  }

  private consent(name: string) {
    const consent = this.consents[name];

    if (!consent.isAccepted) {
      consent.isAccepted = true;

      if (consent.type === CookieConsentType.SCRIPT_CONSENT) {
        this.runScript(consent);
      }
    }
  }

  private setCategoryState(category: CookieConsentCategory, state: boolean) {
    Object.keys(this.consents).forEach(name => {
      const consent = this.consents[name];
      if (consent.category === category) {
        if (state) {
          this.consent(name);
        } else {
          consent.isAccepted = state;
        }
      }
    });
  }

  private setCategoryStates(states: { [catgeory: string]: boolean }) {
    Object.keys(states).forEach(categoryName => {
      this.categories.forEach(category => {
        if (CookieConsentCategory[category.category] === categoryName) {
          // @ts-ignore
          this.setCategoryState(CookieConsentCategory[categoryName], states[categoryName]);
        }
      });
    });
  }

  private close() {
    this.consentMode.next(CookieConsentMode.NONE);
    this.session.cookieConsent = this.consents;
    this.isInitialized = true;
  }

  private runScripts(consents: CookieConsent[]) {
    consents = consents.sort((a) => a.position ?? 0);
    consents.forEach(consent => this.runScript(consent));
  }

  private runScript(consent: CookieConsent) {
    if (consent.scriptUrl
      && (!consent.onlyBrowser || isPlatformBrowser(this.platformId))
      && (!consent.onlyProdMode || environment.production)) {
      const node = document.createElement('script');
      node.src = consent.scriptUrl;
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  private hasConfigChanged(consents: { [name: string]: CookieConsent }) {
    const savedKeys = Object.keys(consents);
    for (const key of Object.keys(COOKIE_CONSENTS.consents)) {
      if (!savedKeys.includes(key)) {
        return true;
      }
    }

    return false;
  }
}
