import { Injectable } from '@angular/core';
import {AccessTokenApiClient} from '../access-token-api-client';
import {SessionService} from '../session.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LocaleService} from '../locale.service';
import {TransferStateService} from '../transfer-state.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends AccessTokenApiClient{

  constructor(protected override readonly cookie: SessionService,
              protected override readonly http: HttpClient,
              protected override readonly router: Router,
              protected override readonly localeService: LocaleService,
              protected override readonly transferStateService: TransferStateService) {
    super(environment.userServiceEndpoint, cookie, http, router, localeService, transferStateService)
  }

  public logout() {
    this.cookie.removeCookies();
    this.router.navigateByUrl('/users/register');
  }
}
