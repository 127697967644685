export enum Cookies {
  NEXT_POPUP_BANNER_DISPLAY_TIME = 'nextPopupBannerDisplayTime',
  COOKIE_CONSENT = 'cookieConsent',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  LOGIN_STATE = 'loginState',
  TOKEN_TYPE = 'tokenType',
  COMPETITION_TYPE_FILTER = 'COMPETITION_TYPE_FILTER',
  CALENDAR_SEARCH_PARAMS = 'CALENDAR_SEARCH_PARAMS',
  TOURNEY_SEARCH_PARAMS = 'TOURNEY_SEARCH_PARAMS',
  MONEY_RANKINGS_FILTER = 'MONEY_RANKINGS_FILTER',
  CURRENCY = 'CURRENCY',
}
