import {CookieConsentCategoryDescription} from '../entities/cookie-consent/cookie-consent-category-description';
import {CookieConsent} from '../entities/cookie-consent/cookie-consent';
import {CookieConsentCategory} from '../entities/cookie-consent/cookie-consent-category.enum';
import {CookieConsentType} from '../entities/cookie-consent/cookie-consent-type.enum';
import {environment} from '../../environments/environment';

export const COOKIE_CONSENTS: { categories: CookieConsentCategoryDescription[], consents: { [name: string]: CookieConsent } } = {
  categories: [
    {
      category: CookieConsentCategory.TECHNICAL_REQUIRED,
      title: $localize`:@@cookieConsentsTechnicalRequired:Technisch notwendig`,
      description: $localize`:@@cookieConsentsTechnicalRequiredDescription:Diese Cookies sind immer aktiviert, da sie für Grundfunktionen der Webseite erforderlich sind. Hierzu zählen Cookies, in denen diese Einstellungen gespeichert werden oder mit denen die Funktionalität der Anfangsliste oder der Suche gewährleistet wird. Außerdem tragen sie zur sicheren und vorschriftsmäßigen Nutzung der Seite bei.`,
      deactivatable: false
    },
    {
      category: CookieConsentCategory.PERFORMANCE,
      title: $localize`:@@cookieConsentsPerformance:Performance`,
      description: $localize`:@@cookieConsentsPerformanceDescription:Mit diesen Cookies können durch die Verfolgung von Nutzerverhalten auf unserer Webseite, die Funktionalität der Seite verbessern. In einigen Fällen wird durch diese Cookies die Geschwindigkeit erhöht, mit der wir ihre Anfrage bearbeiten können. Das Deaktivieren dieser Cookies kann zu einem langsamen Seitenaufruf führen.`,
      deactivatable: true
    },
    {
      category: CookieConsentCategory.SOCIAL,
      title: $localize`:@@cookieConsentsSocial:Social`,
      description: $localize`:@@cookieConsentsSocialDescription:Wir verwenden Cookies und ähnliche Technologien, auch von Drittanbietern, um die ordentliche Funktionsweise der Webseite zu gewährleisten, um die Benutzererfahrung zu optimieren und personalisierte Funktionen und Inhalte für Sie bereitzustellen. Weitere Informationen finden Sie in unserer Datenschutzerklärung.`,
      deactivatable: true
    }
  ],
  consents: {
    shareButtons: {
      title: $localize`:@@cookieConsentsSocialShareButtons:Share Buttons`,
      isAccepted: false,
      category: CookieConsentCategory.SOCIAL,
      type: CookieConsentType.FEATURE_CONSENT,
      description: $localize`:@@cookieConsentsSocialShareButtonsDescription:Wir benötigen die Berechtigung mit ausländischen Servern (Facebook, Twitter, etc.) zu kommunizieren`,
    },
    googleAnalytics: {
      title: $localize`:@@cookieConsentsGoogleAnalyticsTitle:Statistiken zur Nutzung der Anwendung`,
      description: $localize`:@@cookieConsentsGoogleAnalyticsDescription:Wir benötigen die Berechtigung um Statistiken zu Nutzung der Anwendung zu erstellen. Das hilft uns dabei die Performance der Anwendung zu optimieren`,
      category: CookieConsentCategory.PERFORMANCE,
      onlyBrowser: true,
      type: CookieConsentType.SCRIPT_CONSENT,
      isAccepted: false,
      scriptUrl: `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTrackingCode}`,
      position: 0,
      onlyProdMode: true
    },
    googleAnalytics2: {
      title: $localize`:@@cookieConsentsGoogleAnalyticsTitle:Statistiken zur Nutzung der Anwendung`,
      description: $localize`:@@cookieConsentsGoogleAnalyticsDescription:Wir benötigen die Berechtigung um Statistiken zu Nutzung der Anwendung zu erstellen. Das hilft uns dabei die Performance der Anwendung zu optimieren`,
      category: CookieConsentCategory.PERFORMANCE,
      onlyBrowser: true,
      type: CookieConsentType.SCRIPT_CONSENT,
      isAccepted: false,
      scriptUrl: `/public/scripts/google-analytics-script.js`,
      position: 1,
      onlyProdMode: true
    }
  }
};
