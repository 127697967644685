<div class="banner" *ngIf="isActive">
  <div class="container">
    <div class="content">
      <div class="text">
        <h5 i18n="cookie banner | cookie banner headline @@cookieBannerHeadline">
          Diese Webseite verwendet Cookies und andere Technologien
        </h5>
        <p i18n="cookie banner | cookie banner text @@cookieBannerText">
          Wir verwenden Cookies und ähnliche Technologien, auch von Drittanbietern, um die ordentliche Funktionsweise
          der Webseite zu gewährleisten, die Nutzung unseres Angebotes zu analysieren und Ihnen ein bestmögliches
          Einkauferlebnis bieten zu können. Weitere Informationen finden Sie in userer Datenschutzerklärung.</p>
      </div>
      <div class="buttons">
        <div class="w-100">
          <div>
            <button class="btn" (click)="acceptAll()" i18n="cookie banner | Accept all @@acceptAll">
              Alle akzeptieren
            </button>
          </div>
          <div class="settings" (click)="changeSettings()">
            <a i18n="cookie banner | More settings @@moreSettings">Weitere Einstellungen</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
