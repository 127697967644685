import {Component, OnInit} from '@angular/core';
import {Routes} from '../../../enums/routes';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoginStateService} from '../../../services/login-state.service';
import {LoginState} from '@kpro-software/users-lib';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public Routes = Routes;
  public searchForm!: FormControl;
  public isLoading = false;
  public loginState: LoginState | null = null;

  constructor(private readonly fb: FormBuilder,
              private readonly router: Router,
              private readonly loginStateService: LoginStateService) {
  }

  ngOnInit(): void {
    this.loginStateService.getLoginState().subscribe(state => this.loginState = state);
    this.searchForm = this.fb.control(null, [Validators.required, Validators.minLength(2)]);
  }

  public search(): void {
    if (this.searchForm.valid) {
      this.router.navigate([Routes.TOURNEYS], {queryParams: {'name': this.searchForm.value}});
    }
  }

  public isActive(route: Routes): boolean {
    return this.router.url === route;
  }
}
