<input [class]="formControlClass"
       type="text"
       [id]="inputId"
       [ngClass]="{'is-invalid': isInvalid}"
       [formControl]="formControl"
       [readOnly]="disabled"
       [placeholder]="placeholder"
       [ngbTypeahead]="search"
       [resultFormatter]="suggestionFormatter"
       [inputFormatter]="suggestionFormatter"
       (selectItem)="select($event)">
