import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Cookies} from '../enums/cookies';
import * as moment from 'moment';
import {LoginResponse, LoginState} from '@kpro-software/users-lib';
import {CookieConsent} from '../entities/cookie-consent/cookie-consent';
import {environment} from '../../environments/environment';
import {CompetitionCategoryType} from '../enums/competition-category-type';
import {CompetitionCategoryTypeStorage} from '../data/competition-category-type-storage';
import {CalendarSearchParams} from '../data/requests/calendar-search-params';
import {TourneySearchRequest} from '../data/requests/tourney-search-request';
import {MoneyRankingsFilter} from '../data/money-rankings-filter';
import {Currencies} from '../enums/currencies';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private stayLoggedInExpires = moment().add(1, 'year').toDate();

  constructor(private cookieService: CookieService) {
  }

  get cookieConsent(): { [name: string]: CookieConsent } {
    const cookieConsent = this.cookieService.get(Cookies.COOKIE_CONSENT);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
  }

  set cookieConsent(value: { [name: string]: CookieConsent }) {
    const expires = moment().add('day', environment.cookieConsentStorageDuration).toDate();
    this.cookieService.set(Cookies.COOKIE_CONSENT, JSON.stringify(value), expires, '/');
  }

  get nextPopupBannerDisplayTime(): number {
    const nextPopupBannerDisplayTime = Number(this.cookieService.get(Cookies.NEXT_POPUP_BANNER_DISPLAY_TIME));
    return nextPopupBannerDisplayTime ?? null;
  }

  set nextPopupBannerDisplayTime(value: number) {
    this.setCookie(Cookies.NEXT_POPUP_BANNER_DISPLAY_TIME, value);
  }

  get loginState(): LoginState {
    const loginState = this.cookieService.get(Cookies.LOGIN_STATE);
    return loginState ? JSON.parse(loginState) : null;
  }

  set loginState(value: LoginState | null) {
    this.setCookie(Cookies.LOGIN_STATE, JSON.stringify(value));
  }

  public setAccessToken(value: string | null, expires?: number | Date) {
    this.setCookie(Cookies.ACCESS_TOKEN, value, expires);
  }

  get accessToken(): string | null {
    const accessToken = this.cookieService.get(Cookies.ACCESS_TOKEN);
    return accessToken ? accessToken : null;
  }

  public setRefreshToken(value: string, expires?: number | Date): void {
    this.setCookie(Cookies.REFRESH_TOKEN, value, expires);
  }

  get refreshToken(): string | null {
    const refreshToken = this.cookieService.get(Cookies.REFRESH_TOKEN);
    return refreshToken ? refreshToken : null;
  }

  get tokenType(): string {
    const roles = this.cookieService.get(Cookies.TOKEN_TYPE);
    return roles ? JSON.parse(roles) : 'Bearer';
  }

  setTokenType(value: string, expires?: number | Date): void {
    this.setCookie(Cookies.TOKEN_TYPE, JSON.stringify(value), expires);
  }

  public removeAllCookies(): void {
    Object.values(Cookies).forEach(cookie => {
      this.cookieService.delete(cookie, '/');
    });
  }

  public removeCookies(): void {
    // Cookies löschen
    Object.values(Cookies).forEach(cookie => {
      if (cookie !== Cookies.COOKIE_CONSENT && cookie !== Cookies.NEXT_POPUP_BANNER_DISPLAY_TIME) {
        this.cookieService.delete(cookie, '/');
      }
    });
  }

  public saveAll(loginResponse: LoginResponse, permanent = false) {
    let expires;
    if (permanent) {
      expires = this.stayLoggedInExpires;
    }

    this.setAccessToken(loginResponse.accessToken, expires);
    this.setRefreshToken(loginResponse.refreshToken, expires);
    this.setTokenType(loginResponse.tokenType, expires);
  }

  private setCookie(name: string, value: any, expires?: number | Date) {
    if (expires === null) {
      this.cookieService.set(name, value, 0, '/');
    } else {
      this.cookieService.set(name, value, expires, '/');
    }
  }

  public getCompetitionCategoryTypeStrings() {
    const sportTypes: CompetitionCategoryType[] = [];
    const competitionCategoryTypeFilter = this.getCompetitionCategoryTypeFilter();
    Object.values(CompetitionCategoryType)
      .forEach(type => {
        if (competitionCategoryTypeFilter[type]) {
          sportTypes.push(type);
        }
      });

    return sportTypes;
  }

  public getCompetitionCategoryTypeFilter() {
    const currentlyStoredFilter = this.cookieService.get(Cookies.COMPETITION_TYPE_FILTER);

    if (!currentlyStoredFilter) {
      const competitionCategoryTypeStorage: CompetitionCategoryTypeStorage = {
        jumping: true,
        dressage: true,
        eventing: true
      };
      this.cookieService.set(Cookies.COMPETITION_TYPE_FILTER, JSON.stringify(competitionCategoryTypeStorage));
      return competitionCategoryTypeStorage;
    }

    return JSON.parse(this.cookieService.get(Cookies.COMPETITION_TYPE_FILTER));
  }

  public setCompetitionCategoryTypeFilter(competitionCategoryTypeFilter: string) {
    this.setCookie(Cookies.COMPETITION_TYPE_FILTER, competitionCategoryTypeFilter);
  }

  public getSearchParams(cookieKey: Cookies, searchWord: string|null = null) {
    const currentlyStoredSearchParams = this.cookieService.get(cookieKey);

    if (!currentlyStoredSearchParams) {
      switch (cookieKey) {
        case Cookies.TOURNEY_SEARCH_PARAMS:
          return this.getDefaultTourneySearchParams(searchWord);
        case Cookies.CALENDAR_SEARCH_PARAMS:
          return this.getDefaultCalendarSearchParams();
        default:
          console.error('No Function to load default values for the cookie: ' + cookieKey);
          return null;
      }
    }
    return JSON.parse(this.cookieService.get(cookieKey));
  }

  public setSearchParams(cookieKey: Cookies, filters: TourneySearchRequest) {
    this.cookieService.set(cookieKey, JSON.stringify(filters));
  }

  public getMoneyRankingFilter(): MoneyRankingsFilter {
    const currentlyStoredFilter = this.cookieService.get(Cookies.MONEY_RANKINGS_FILTER);

    if (!currentlyStoredFilter) {
      let initStartDate = new Date();
      initStartDate.setFullYear(initStartDate.getFullYear() - 1);
      const moneyRankingsFilter: MoneyRankingsFilter = {
        horseName: null,
        riderName: null,
        sireName: null,
        mareSireName: null,
        studbook: null,
        fromDate: moment(initStartDate).format('YYYY-MM-DD'),
        toDate: null,
        sportTypes: null
      };
      this.cookieService.set(Cookies.MONEY_RANKINGS_FILTER, JSON.stringify(moneyRankingsFilter));
      return moneyRankingsFilter;
    }
    return JSON.parse(currentlyStoredFilter);
  }

  public setMoneyRankingsFilter(filter: MoneyRankingsFilter) {
    this.cookieService.set(Cookies.MONEY_RANKINGS_FILTER, JSON.stringify(filter));
  }

  private getDefaultTourneySearchParams(searchWord: string | null) {
    const tourneySearchRequest: TourneySearchRequest = {
      page: 1,
      itemsPerPage: 20,
      resultsRequired: false,
      fromDate: moment().subtract(1, 'year').valueOf(),
      searchWord: searchWord ?? undefined
    };
    this.cookieService.set(Cookies.TOURNEY_SEARCH_PARAMS, JSON.stringify(tourneySearchRequest));
    return tourneySearchRequest;
  }

  private getDefaultCalendarSearchParams() {
    const calendarSearchParams: CalendarSearchParams = {
      page: 1,
      skipCount: false,
      itemsPerPage: 20,
      resultsRequired: false,
      fromDate: moment().subtract(1, 'day').startOf('day').valueOf(),
      toDate: null
    };
    this.cookieService.set(Cookies.CALENDAR_SEARCH_PARAMS, JSON.stringify(calendarSearchParams));
    return calendarSearchParams;
  }

  public getCurrency() {
    const currency = this.cookieService.get(Cookies.CURRENCY);

    if (!currency) {
      this.cookieService.set(Cookies.CURRENCY, Currencies.EUR);
      return Currencies.EUR;
    }
    return currency;
  }

  public setCurrency(currency: string) {
    this.cookieService.set(Cookies.CURRENCY, currency);
  }
}
