import {Component, Input, OnInit} from '@angular/core';
import {CookieConsent} from '../../../../entities/cookie-consent/cookie-consent';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-single-cookie-consent-modal',
  templateUrl: './single-cookie-consent-modal.component.html',
  styleUrls: ['./single-cookie-consent-modal.component.scss']
})
export class SingleCookieConsentModalComponent implements OnInit {

  @Input()
  public consent!: CookieConsent;

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  save(): void {
    this.activeModal.close(true);
  }
}
