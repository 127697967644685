import {Component, Input, OnInit} from '@angular/core';
import {Country} from '../../../../entities/results/country';
import {CountriesService} from '../../../../services/pedigree/countries.service';
import {MessageBoxService} from '../../../../services/message-box.service';
import {RegisterError} from '../../../../data/responses/errors/register/register-error';
import {RegisterErrorType} from '../../../../data/responses/errors/register/register-error-type.enum';
import {RegisterRequest} from '../../../../data/requests/user-service/register-request';
import {SignInService} from '../../../../services/sign-in.service';
import {ToastrService} from 'ngx-toastr';
import {SimplePages} from '../../../../enums/simple-pages';
import {Routes} from '../../../../enums/routes';
import {TargetService} from '@kpro-software/users-lib';
import {RedirectService} from '../../../../services/redirect.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {
  @Input()
  public focus = false;

  @Input()
  public redirectUrl?: Routes;

  public readonly targetService = TargetService.RESULTS;
  public SimplePages = SimplePages;
  public Routes = Routes;
  public countries: Country[] = [];

  constructor(private readonly messageBoxService: MessageBoxService,
              private readonly countriesService: CountriesService,
              private readonly signInService: SignInService,
              private readonly redirectService: RedirectService,
              private readonly toast: ToastrService) {
  }

  ngOnInit() {
    this.countriesService.getCountries().subscribe(countries => this.countries = countries);
  }

  public onRegisterError(error: any): void {
    const registerError: RegisterError = error.error;

    if (registerError.errorType === RegisterErrorType[RegisterErrorType.DUPLICATE_USERNAME]) {
      this.messageBoxService.show(
        $localize`:@@errorOccurred:Ein Fehler ist aufgetreten`,
        $localize`:@@registerErrorDuplicateEmail:Für die gewählte E-Mail-Adresse besteht bereits ein Account`,
      );

    } else {
      this.messageBoxService.show(
        $localize`:@@errorOccurred:Ein Fehler ist aufgetreten`,
        $localize`:@@checkEntries:Bitte überprüfen Sie Ihre Eingaben`
      );
    }
  }

  public onRegister(registerRequest: RegisterRequest): void {
    this.messageBoxService.show(
      $localize`:@@registerSuccess:Vielen Dank für Ihre Registrierung bei Horsetelex!`,
      $localize`:@@registerSuccessMessage:Sie haben eine E-Mail an die angegebene E-Mail-Adresse erhalten. Bitte verifizieren Sie ihre E-Mail-Adresse durch einen Klick auf den darin enthaltenen Link.`,
    ).result.subscribe()
      .add(() => {
        const url = this.redirectUrl ?? this.redirectService.buildRedirect();
        this.signInService.login(registerRequest.emailAddress, registerRequest.password, false, url)
          .subscribe(() => this.toast.success($localize`:@@loginSuccess:Sie haben sich erfolgreich eingeloggt`));
      });
  }
}
