import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {AdsApiService} from './ads-api.service';
import {EventMetaData} from '../../entities/ads/event-meta-data';
import {PopupBannerMetaData} from '../../entities/ads/popup-banner-meta-data';
import {HeadlineBlockEventMetaData} from '../../entities/ads/headline-block-event-meta-data';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdEventService {

  constructor(private readonly apiService: AdsApiService,
              @Inject(PLATFORM_ID) private platformId: string) {
  }

  public emitAdClickEvent(metaData: object): Observable<void> {
    return this.apiService.post('events/click/ad', metaData);
  }

  public emitAdImpressionEvent(metaData: object): Observable<void> {
    return this.apiService.post('events/impression/ad', metaData);
  }

  public canSendImpression(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public emitPopupBannerClickEvent(metaData: PopupBannerMetaData): Observable<void> {
    return this.apiService.post('events/click/popup-banner', metaData);
  }

  public emitPopupBannerImpressionEvent(metaData: PopupBannerMetaData): Observable<void> {
    return this.apiService.post('events/impression/popup-banner', metaData);
  }

  public emitHeadlineBlockClickEvent(metaData: HeadlineBlockEventMetaData): Observable<void> {
    return this.apiService.post('events/click/headline-block', metaData);
  }

  public emitHeadlineBlockImpressionEvent(metaData: HeadlineBlockEventMetaData): Observable<void> {
    return this.apiService.post('events/impression/headline-block', metaData);
  }

  public getMetaData(): EventMetaData {
    return {
      browserLanguage: navigator.language,
      resolutionWidth: window.screen.width,
      resolutionHeight: window.screen.height,
      timestamp: Date.now(),
      service: 'RESULTS'
    };
  }
}
