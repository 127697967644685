<div id="header-first-line">
  <div class="container">
    <div class="float-start" id="more-horsetelex-sites">
      <a id="pedigree-link"
         i18n="default layout header | HorsetelexPedigree layout@@headerPedigree"
      (click)="navigateToPedigree()">
        HorseTelex Pedigree
      </a>
      <a id="sales-link" href="http://horsetelexsales.com/" target="_blank"
         i18n="default layout header | HorsetelexSales layout@@headerSales">HorseTelex Sales</a>
    </div>
    <div class="float-end choose-language-container">
      <a class="language-link"
         [class.active]="currentLocale === Locale.DE"
         (click)="changeLanguage(Locale.DE)">DE</a>
      <span class="separator"></span>
      <a class="language-link"
         [class.active]="currentLocale === Locale.EN"
         (click)="changeLanguage(Locale.EN)">EN</a>
      <span class="separator"></span>
      <a class="language-link"
         [class.active]="currentLocale === Locale.FR"
         (click)="changeLanguage(Locale.FR)">FR</a>
      <span class="separator"></span>
      <a class="language-link"
         [class.active]="currentLocale === Locale.NL"
         (click)="changeLanguage(Locale.NL)">NL</a>
    </div>
  </div>
</div>
<div id="header-second-line">
  <div class="container">
    <div class="row">
      <div class="col overflow-dots d-flex align-items-center">
        <ng-container *ngIf="loginState">
          <div class="navigation user">
            <ng-container *ngIf="loginState.user.verified === true">
              <div class="username" [ngbTooltip]="loginState.user.name" container="body"
                   i18n="default layout header | welcome layout@@headerWelcome">
                Willkommen {{loginState.user.firstname}}
              </div>
              <span class="subscription" *ngIf="loginState.membership"
                    i18n="default layout header | subscription: {membership} layout@@headerSubscription">
                Abonnement: {{loginState.membership.name}}
            </span>
            </ng-container>
            <div class="small" *ngIf="loginState?.user?.verified === false">
              <a class="ms-0" [routerLink]="Routes.VALIDATE_EMAIL"
                 i18n="default layout header | Please confirm your email address @@pleaseConfirmEmail">
                Bitte bestätigen Sie ihre E-mail-Adresse
              </a>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-auto text-end">
        <div class="navigation">
          <ng-container *ngIf="!loginState">
            <a [routerLink]="Routes.LOGIN" fragment="register"
               i18n="default layout header | register layout@@headerRegister">Registrieren</a>
            <a [routerLink]="Routes.LOGIN"
               i18n="default layout header | login layout@@headerLogin">Login</a>
          </ng-container>
          <ng-container *ngIf="loginState">
            <a (click)="logout()" i18n="default layout header | logout layout@@headerLogout">Logout</a>
            <a [routerLink]="Routes.USER_DATA" i18n="default layout header | My Ht @@headerMyHt">Mein Ht</a>
          </ng-container>
          <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.ABOUT_US"
             i18n="default layout header | about us layout@@headerAboutUs">Über uns</a>
          <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.HELP_AND_INFO"
             i18n="default layout header | help & info layout@@headerHelpAndInfo">
            Hilfe und Info
          </a>
          <a class="isv-and-ipv" [routerLink]="Routes.SIMPLE_PAGE + SimplePages.ISV_AND_IPV"
             i18n="default layout header | ISV & IPV @@headerIsvAndIpv">Über ISV & IPV</a>
          <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.CONTACT"
             i18n="default layout header | contact layout@@headerContact">Kontakt</a>
        </div>
      </div>
    </div>
  </div>
</div>
