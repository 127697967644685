import {Injectable} from '@angular/core';
import {ApiClient} from '../api/api-client';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {LocaleService} from '../locale.service';
import {TransferStateService} from '../transfer-state.service';

@Injectable({
  providedIn: 'root'
})
export class AdsApiService extends ApiClient {

  constructor(
    protected override readonly http: HttpClient,
    protected override readonly router: Router,
    protected override readonly localeService: LocaleService,
    protected override transferStateService: TransferStateService
  ) {
    super(environment.adsEndpoint, http, router, localeService, transferStateService);
  }
}
