import {Component, OnInit} from '@angular/core';
import {AdsService} from '../../../services/ads/ads.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {


  constructor() {
  }

  ngOnInit() {
  }

}
