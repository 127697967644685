import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router, UrlTree} from '@angular/router';
import {Routes} from '../enums/routes';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  private readonly redirectParamName = 'redirect';

  constructor(private readonly router: Router,
              private readonly route: ActivatedRoute) {
  }

  public buildUrlTreeWithRedirectParam(url: string, redirectUrl: string): UrlTree {
    if (url.length > 0 && url.startsWith('/')) {
      url = url.substring(1);
    }

    const queryParams: Params = {};
    queryParams[this.redirectParamName] = redirectUrl;

    return this.router.createUrlTree([url], {queryParams});
  }

  public navigateWithRedirectParam(url: string, redirectUrl: string): void {
    this.router.navigateByUrl(this.buildUrlTreeWithRedirectParam(url, redirectUrl))
  }

  public navigateKeepRedirectParam(url: string): void {
    const redirectUrl = this.getRedirectUrl();

    if (redirectUrl) {
      this.navigateWithRedirectParam(url, redirectUrl);

    } else {
      this.router.navigateByUrl(url);
    }
  }

  public redirect(defaultRedirectUrl: string = Routes.HOME): void {
    const redirectUrl = this.getRedirectUrl();

    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigateByUrl(defaultRedirectUrl);
    }
  }

  public getRedirectUrl(): string | null {
    return this.route.snapshot.queryParamMap.get(this.redirectParamName) ?? null;
  }

  public buildRedirect(defaultUrl: string = Routes.HOME): UrlTree {
    return this.router.createUrlTree([this.getRedirectUrl() ?? defaultUrl]);
  }

  public redirectIfRedirectParam(): void {
    const redirectUrl = this.getRedirectUrl();

    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl);
    }
  }
}
