<div [formGroup]="form" (keydown.enter)="search()">
  <div class="row">
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="Name @@name" for="name">Name</label>
        <input [class]="formControlClass" id="name" formControlName="name"
               [ngClass]="{'is-invalid': isControlInvalid('name')}">
        <div class="invalid-feedback">{{getErrorMessages('name')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="search horse | sire @@searchHorseSire" for="father">Vater</label>
        <input [class]="formControlClass" id="father" formControlName="father"
               [ngClass]="{'is-invalid': isControlInvalid('father')}">
        <div class="invalid-feedback">{{getErrorMessages('father')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="search horse | dam @@searchHorseDam" for="mother">Mutter</label>
        <input [class]="formControlClass" id="mother" formControlName="mother"
               [ngClass]="{'is-invalid': isControlInvalid('mother')}">
        <div class="invalid-feedback">{{getErrorMessages('mother')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="search horse | dam sire @@searchHorseDamsire" for="fatherOfMother">Muttervater</label>
        <input [class]="formControlClass" formControlName="fatherOfMother" id="fatherOfMother"
               [ngClass]="{'is-invalid': isControlInvalid('fatherOfMother')}">
        <div class="invalid-feedback">{{getErrorMessages('fatherOfMother')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="search horse | year @@searchHorseYear" for="year">Jahr</label>
        <input type="number" [class]="formControlClass" id="year" formControlName="year"
               [ngClass]="{'is-invalid': isControlInvalid('year')}">
        <div class="invalid-feedback">{{getErrorMessages('year')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="Fei @@fei" for="fei">Fei</label>
        <input [class]="formControlClass" id="fei" formControlName="fei"
               [ngClass]="{'is-invalid': isControlInvalid('fei')}">
        <div class="invalid-feedback">{{getErrorMessages('fei')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" i18n="Studbook @@studbook">Stammbuch</label>
        <app-typeahead inputId="studbook"
                       [formControlClass]="formControlClass"
                       [suggestionHandler]="studbookService.getSuggestions"
                       [findHandler]="studbookService.getStudbook"
                       [isInvalid]="isControlInvalid('studbook')"
                       [ngClass]="{'is-invalid': isControlInvalid('studbook')}"
                       formControlName="studbook"
                       displayFieldName="name"
                       idFieldName="id"></app-typeahead>
        <div class="invalid-feedback">{{getErrorMessages('studbook')}}</div>
      </div>
    </div>
    <div [class]="colClass">
      <div class="form-group">
        <label class="form-label" for="reg">
          <span i18n="search horse | ueln @@searchHorseUeln">UELN</span>
          /
          <span i18n="search horse | studbook number @@searchHorseStudbookNumber">Stammbuchnummer</span>
        </label>
        <input [class]="formControlClass" id="reg" formControlName="reg"
               [ngClass]="{'is-invalid': isControlInvalid('reg')}">
        <div class="invalid-feedback">{{getErrorMessages('reg')}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div [class]="resetColClass">
      <button class="btn btn-outline-dark w-100"
              [ngClass]="{'btn-sm': size === 'sm'}"
              (click)="resetForm()"
              i18n="Reset@@reset">
        Zurücksetzen
      </button>
    </div>
    <div [class]="submitColClass">
      <button class="btn btn-dark w-100"
              [ngClass]="{'btn-disabled': form.invalid || isFormEmpty(), 'btn-sm': size === 'sm'}"
              (click)="search()"
              i18n="Search @@search">
        Suchen
      </button>
    </div>
  </div>
</div>
