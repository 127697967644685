import {FormGroup, ValidationErrors} from '@angular/forms';
import {ValidationService} from '../services/validation.service';

export class FormComponent {
  public form!: FormGroup;

  constructor(protected readonly validationService: ValidationService) {
  }

  public isControlInvalid(name: string, form?: FormGroup) {
    const control = form ? form.get(name) : this.form.get(name);
    return this.validationService.showInvalid(control);
  }

  public getErrorMessages(controlName: string, form?: FormGroup) {
    const control = form ? form.get(controlName) : this.form.get(controlName);
    return this.getFirstErrorMessage(control?.errors);
  }

  public getErrorMessageOfFormGroup(form?: FormGroup) {
    const errors = form ? form.errors : this.form.errors;
    return this.getFirstErrorMessage(errors);
  }

  private getFirstErrorMessage(errors: ValidationErrors | null | undefined) {
    const errorMessages: string[] = this.validationService.getErrorMessages(errors);

    if (errorMessages.length > 1) {
      return errorMessages[0];

    } else {
      return errorMessages;
    }
  }

  protected trimFormValue(formValue: { [key: string]: any }, nullEmptyStrings = false): { [key: string]: any } {
    const trimmedValue: { [key: string]: string } = {};
    Object.keys(formValue).forEach(key => {
      if (typeof formValue[key] === 'string') {
        let value = formValue[key].trim();

        if (nullEmptyStrings && value === '') {
          value = null;
        }

        trimmedValue[key] = value;

      } else {
        trimmedValue[key] = formValue[key];
      }
    });

    return trimmedValue;
  }
}
