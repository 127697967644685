<app-navigation-bar></app-navigation-bar>
<div class="content-area">
  <div>
    <div class="inner">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-right-sidebar class="right-side-bar"></app-right-sidebar>
</div>
<app-footer></app-footer>
