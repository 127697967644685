import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(values: any[], separator = ', ', removeEmptyValues = true): unknown {
    const notEmptyValues: any = [];

    if (removeEmptyValues) {
      values.forEach(value => {
        if (value || value === 0) {
          notEmptyValues.push(value);
        }
      })
      return notEmptyValues.join(separator);

    } else {
      return values.join(separator);
    }
  }

}
