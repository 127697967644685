import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocaleService} from '../locale.service';
import {TransferStateService} from '../transfer-state.service';

export abstract class ApiClient {
  protected endpoint: string;

  protected constructor(
    endpoint: string,
    protected readonly http: HttpClient,
    protected readonly router: Router,
    protected readonly localeService: LocaleService,
    protected transferStateService: TransferStateService
  ) {
    this.endpoint = endpoint;
  }

  public get<T>(path: string, params: {} = {}): Observable<T> {
    const request = this.http.get<T>(this.endpoint + path, this.getHttpOptions(params));
    return this.modifyObservableWithTransferState(request, this.endpoint + path);
  }

  public post<T>(path: string, body: {}, params: {} = {}): Observable<T> {
    const request = this.http.post<T>(this.endpoint + path, body, this.getHttpOptions(params));
    return this.modifyObservableWithTransferState(request, this.endpoint + path);
  }

  public put<T>(path: string, body: {}, params: {} = {}) {
    const request = this.http.put<T>(this.endpoint + path, body, this.getHttpOptions(params));
    return this.modifyObservableWithTransferState(request, this.endpoint + path);
  }

  public delete<T>(path: string, body: {}, params: {} = {}) {
    const httpOptions = this.getHttpOptions(params);
    const request = this.http.delete<T>(this.endpoint + path, Object.assign(httpOptions, {body}));
    return this.modifyObservableWithTransferState(request, this.endpoint + path);
  }

  protected getHttpOptions(params: { [key: string]: any } = {}) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Locale: this.localeService.localeId
      }),
      params: this.createParams(params)
    };
  }

  protected createParams(params: { [key: string]: any }) {
    let getParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        getParams = getParams.append(key, params[key]);
      }
    }

    return getParams;
  }

  protected modifyObservableWithTransferState<T>(observable: Observable<T>, key: string): Observable<T> {
    return this.transferStateService.fetch(key, observable);
  }
}
