<footer>
  <div class="footer-first-line">
    <div class="container">
      <div class="d-flex align-items-start">
        <div class="first-row d-inline-block">
          <img src="/public/images/logo-red-small.png" [routerLink]="Routes.HOME" class="footer-img">
          <div class="results-description">
          <span>
            <div i18n="default layout footer | International equestrian results from all over the world @@footerDescription">
              Internationale Reitergebnisse aus der ganzen Welt
            </div>
          </span>
            <div class="mt-3" i18n="default layout footer | HorseTelex @@horseTelex">HorseTelex ®</div>
            <div class="mt-3" i18n="default layout footer | IPV Index @@ipvIndex">IPV Index ©</div>
            <div class="mt-3" i18n="default layout footer | ISV Rankings @@isvRankings">ISV Rankings ©</div>
          </div>
        </div>
        <div class="second-row d-inline-block">
          <span class="caption" i18n="default layout footer | menu layout@@footerMenu">Menu</span>
          <a class="link" [routerLink]="Routes.HOME" i18n="default layout footer | home layout@@footerHome">Home</a>
          <a class="link" [routerLink]="Routes.SIMPLE_PAGE + SimplePages.ABOUT_US"
             i18n="default layout footer | about us layout@@footerAboutUs">Über
            uns</a>
          <a class="link" [routerLink]="Routes.SIMPLE_PAGE + SimplePages.HELP_AND_INFO"
             i18n="default layout footer | help & info layout@@HelpAndInfo">Hilfe
            und Info</a>
        </div>
        <div class="third-row d-inline-block ">
          <span class="caption" i18n="default layout footer | Calendar and Results @@footerCalendarAndResults">Kalender und Ergebnisse</span>
          <a class="link" [routerLink]="Routes.CALENDAR" i18n="default layout footer | Calendar @@footerCalendar">Kalender</a>
          <a class="link" [routerLink]="Routes.RESULTS" i18n="default layout footer | Results @@footerResults">Ergebnisse</a>
          <!--        TODO: Hier muss der User noch geprüft werden-->
          <a class="link" [routerLink]="Routes.SUBSCRIBE" i18n="default layout footer | Create Alerts @@footerCreateAlerts">Benachrichtigungen erstellen</a>
          <a class="link" [routerLink]="Routes.TOURNEYS" [queryParams]="{sportTypes: ['jumping']}" i18n="default layout footer | Jumping Results @@footerJumpingResults">Sprungergebnisse</a>
          <a class="link" [routerLink]="Routes.TOURNEYS" [queryParams]="{sportTypes: ['dressage']}" i18n="default layout footer | Dressage Results @@footerDressageResults">Dressurergebnisse</a>
          <a class="link" [routerLink]="Routes.TOURNEYS" [queryParams]="{sportTypes: ['eventing']}" i18n="default layout footer | Eventing Results @@footerEventingResults">Vielseitigkeitsergebnisse</a>
        </div>
      </div>
      <div class="terms-and-condition">
        <div i18n="default layout footer | HorseTelex is a registered trademark of HorseTelex B.V. By using this site, you agree to the General Terms and Conditions @@footerTermsAndConditions">
          HorseTelex ® is a registered trademark of HorseTelex B.V. By using this site, you agree to the
          <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.TERMS_AND_CONDITIONS"><u>General Terms and Conditions.</u></a>
        </div>
        <div i18n="default layout footer | All rights reserved. Copyright 2007 – X HorseTelex B.V. @@footerCopyright">
          All rights reserved. Copyright © 2007 – {{getCurrentYear()}} HorseTelex B.V.
        </div>
      </div>
    </div>
  </div>
  <div class="footer-second-line">
    <div class="container">
      <div class="law-links">
        <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.DISCLAIMER"
           i18n="default layout footer | disclaimer layout@@footerDisclaimer">Disclaimer</a>
        <span class="separator"></span>
        <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.COOKIES"
           i18n="default layout cookies | menu layout@@footerCookies">Cookies</a>
        <span class="separator"></span>
        <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.PRIVACY_STATEMENT"
           i18n="default layout footer | privacy statement layout@@footerPrivacyStatement">
          Privacy Statement</a>
        <span class="separator"></span>
        <a class="hover-pointer" (click)="openCookieSettings()" i18n="footer | Cookie settings @@footerCookieSettings">
          Cookie-Einstellungen
        </a>
        <span class="separator"></span>
        <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.LEGAL_NOTICE" i18n="Legal Notice @@legalNotice">
          Impressum
        </a>
        <span class="separator"></span>
        <a [routerLink]="Routes.SIMPLE_PAGE + SimplePages.TERMS_AND_CONDITIONS"
           i18n="GTC @@gtc" class="text-uppercase">
          AGB
        </a>
      </div>
    </div>
  </div>
</footer>
