import {Component, OnInit} from '@angular/core';
import {Routes} from '../../../enums/routes';
import {Router} from '@angular/router';
import {SimplePages} from '../../../enums/simple-pages';
import {LoginStateService} from '../../../services/login-state.service';
import {LoginState, SingleSignOnService} from '@kpro-software/users-lib';
import {LocaleService} from '../../../services/locale.service';
import {Locale} from '../../../enums/locale';
import {SessionService} from '../../../services/session.service';
import {Memberships} from '../../../enums/memberships';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  public Routes = Routes;
  public SimplePages = SimplePages;
  public loginState!: LoginState | null;
  public Locale = Locale;
  public currentLocale!: string;

  constructor(private readonly route: Router,
              private readonly loginStateService: LoginStateService,
              private readonly localeService: LocaleService,
              private readonly session: SessionService,
              private readonly singleSignOnService: SingleSignOnService) {
  }

  ngOnInit(): void {
    this.currentLocale = this.localeService.localeId.toUpperCase();
    this.loginStateService.getLoginState().subscribe(state => this.loginState = state);
  }

  public logout(): void {
    this.loginStateService.logout();
  }

  public changeLanguage(locale: Locale): void {
    if (locale !== this.currentLocale) {
      this.singleSignOnService.changeLanguage(locale.toLowerCase());
      this.currentLocale = locale;
    }
  }

  public navigateToPedigree() {
    this.singleSignOnService.changePage(this.localeService.getPedigreeUrl());
  }

  public isTopMember(): boolean {
    return this.loginState?.membership?.id === Memberships.TOP;
  }

  public isPlusMember(): boolean {
    return this.loginState?.membership?.id === Memberships.PLUS;
  }

  public isAlertsMember(): boolean {
    return this.loginState?.membership?.id === Memberships.ALERTS;
  }
}
