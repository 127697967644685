import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {PaginationComponent} from './ui/pagination/pagination.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {AdBannerContainerComponent} from './ui/ad-banner-container/ad-banner-container.component';
import {EnumValuesToArrayPipe} from './pipes/enum-values-to-array.pipe';
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {IpvTypePipe} from './pipes/ipv/ipv-type.pipe';
import {IpvDomainPipe} from './pipes/ipv/ipv-domain.pipe';
import {IvPaginationComponent} from './ui/iv-pagination/iv-pagination.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingScreenComponent} from './ui/loading-screen/loading-screen.component';
import {PedigreeHorseLinkComponent} from './ui/pedigree-horse-link/pedigree-horse-link.component';
import {PrizePipe} from './pipes/prize.pipe';
import {ConfirmComponent} from './ui/confirm/confirm.component';
import {CheckComponent} from './ui/check/check.component';
import {GenderPipe} from './pipes/gender.pipe';
import {MultiSelectComponent} from './form-controls/multi-select/multi-select.component';
import {HorseSearchFormComponent} from './ui/horse-search-form/horse-search-form.component';
import {TypeaheadComponent} from './form-controls/typeahead/typeahead.component';
import {AutofocusDirective} from './directives/autofocus.directive';
import {JoinPipe} from './pipes/join.pipe';
import {BooleanPipe} from './pipes/boolean.pipe';
import {RouterModule} from '@angular/router';
import {UsersLibModule} from '@kpro-software/users-lib';
import {LoginComponent} from './ui/login/login.component';
import {StaticSrcDirective} from './directives/static-src.directive';
import {RegistrationFormComponent} from '../general/ui/registration-form/registration-form.component';
import {SignInComponent} from '../general/pages/sign-in/sign-in.component';
import {ResetPasswordFormComponent} from '../general/ui/reset-password-form/reset-password-form.component';
import {SubscribeFunnelComponent} from '../account/ui/subscribe-funnel/subscribe-funnel.component';
import {BackLinkComponent} from './ui/back-link/back-link.component';
import {HorsetelexAdsModule} from '@kpro-software/horsetelex-ads';

const declarationsAndExports = [
  PaginationComponent,
  AdBannerContainerComponent,
  IpvTypePipe,
  EnumValuesToArrayPipe,
  EnumToArrayPipe,
  IpvDomainPipe,
  LoadingScreenComponent,
  PrizePipe,
  ConfirmComponent,
  CheckComponent,
  GenderPipe,
  MultiSelectComponent,
  HorseSearchFormComponent,
  IvPaginationComponent,
  PedigreeHorseLinkComponent,
  TypeaheadComponent,
  AutofocusDirective,
  BooleanPipe,
  JoinPipe,
  LoginComponent,
  StaticSrcDirective,
  SignInComponent,
  RegistrationFormComponent,
  ResetPasswordFormComponent,
  SubscribeFunnelComponent,
  BackLinkComponent,
]

@NgModule({
  declarations: [
    ...declarationsAndExports,
  ],
  imports: [
    CommonModule,
    NgbModule,
    HorsetelexAdsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    UsersLibModule,
  ],
  exports: [
    HttpClientModule,
    HorsetelexAdsModule,
    UsersLibModule,
    ...declarationsAndExports,
  ],
  providers: [
    CurrencyPipe
  ]
})
export class SharedModule {
}
