import {Component, Input, OnInit} from '@angular/core';
import {Routes} from '../../../../enums/routes';
import {Location} from '@angular/common';

@Component({
  selector: 'app-back-link',
  templateUrl: './back-link.component.html',
  styleUrls: ['./back-link.component.scss']
})
export class BackLinkComponent implements OnInit {

  @Input()
  public defaultUrl = Routes.HOME;

  public _url!: string;

  constructor(private readonly location: Location) {
  }

  ngOnInit(): void {
  }

  public navigateToPreviousPage() {
    this.location.back();
  }
}
