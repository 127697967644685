<div [ngClass]="{'btn-disabled':disabled}">
  <div class="d-flex align-items-center" [ngClass]="flexAlignment">
    <div *ngIf="!hideCount">
      {{totalItems}}<span *ngIf="exceedsMaxItems">+</span>&nbsp;
      <span i18n="pagination | results pagination@@paginationResults">Ergebnisse</span>
    </div>
    <ngb-pagination class="results-pagination"
                    [collectionSize]="getTotalItems()"
                    [ellipses]="true"
                    [maxSize]="3"
                    [rotate]="true"
                    [pageSize]="itemsPerPage"
                    [page]="page"
                    (pageChange)="change($event)"
                    aria-label="Pagination"></ngb-pagination>
  </div>
</div>
