import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MessageBoxService} from '../../../../services/message-box.service';
import {TargetService} from '@kpro-software/users-lib';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  public readonly targetService = TargetService.RESULTS;

  constructor(private fb: FormBuilder,
              private messageBoxService: MessageBoxService) {
  }

  ngOnInit(): void {
  }

  onResetKeyCreated(): void {
    this.messageBoxService.show(
      $localize`:@@passwordRequest:Passwort Anfrage`,
      $localize`:@@passwordRequestMessage:Falls der eingegebenen E-Mail-Adresse ein Account zugeordnet ist, erhalten Sie in Kürze Instruktionen zum Zurücksetzen Ihres Passwortes an die angegebene E-Mail-Adresse`
    );
  }

  onError(): void {
    this.messageBoxService.show(
      $localize`:@@passwordRequestErrorTitle:Fehler beim Zurücksetzen des Passworts`,
      $localize`:@@passwordRequestMessageErrorTitle:Beim Zurücksetzen des Passworts ist ein Fehler aufgetreten. Bitte versuche es zu einem späteren Zeitpunkt erneut.`
    );
  }
}
