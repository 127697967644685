import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {PopupBanner} from '../../../entities/ads/popup-banner';
import {isPlatformBrowser} from '@angular/common';
import {filter} from 'rxjs/operators';
import {AdEventService} from '../../../services/ads/ad-event.service';
import {AdsService} from '../../../services/ads/ads.service';
import {SessionService} from '../../../services/session.service';
import {PopupBannerMetaData} from '../../../entities/ads/popup-banner-meta-data';
import {PopUpBannerFormat} from '../../../enums/pop-up-banner-format';

@Component({
  selector: 'app-popup-banner',
  templateUrl: './popup-banner.component.html',
  styleUrls: ['./popup-banner.component.scss']
})
export class PopupBannerComponent implements OnInit {
  @ViewChild('image') imageRef!: ElementRef;
  @ViewChild('video') videoPlayer!: ElementRef;

  public PopUpBannerFormat = PopUpBannerFormat;
  public popupBanner: PopupBanner | null = null;
  private listener: () => void = () => {};

  constructor(private readonly adsService: AdsService,
              private readonly sessionService: SessionService,
              private readonly renderer: Renderer2,
              private readonly adEventService: AdEventService,
              @Inject(PLATFORM_ID) private readonly platformId: string) {
  }

  ngOnInit(): void {
    // Popup-Banner anzeigen, wenn der nächste Anzeige-Zeitpunkt erreicht oder der Cookie nicht vorhanden ist
    const nextPopupBannerDisplayTime = this.sessionService.nextPopupBannerDisplayTime;
    const currentTimestamp = new Date().getTime();
    if (isPlatformBrowser(this.platformId) && (!nextPopupBannerDisplayTime || nextPopupBannerDisplayTime < currentTimestamp)) {
      this.showPopupBanner();
    }
  }

  public showPopupBanner() {
    this.adsService.getActivePopupBanner()
      .pipe(filter(value => !!value))
      .subscribe(banner => {
        this.popupBanner = banner;

        // Banner schließen, wenn auf etwas anderes als das Bild geklickt wird
        this.listener = this.renderer.listen('window', 'click', (event: Event) => {
          if ((this.imageRef && event.target !== this.imageRef.nativeElement) || (this.videoPlayer && event.target !== this.videoPlayer.nativeElement)) {
            this.close();
          }
        });

        // Zeitpunkt speichern, ab dem der Popup-Banner erneut angezeigt wird
        this.sessionService.nextPopupBannerDisplayTime = new Date().getTime() + banner.intervalSeconds * 1000;

        // Impressions-Event speichern, wenn die Plattform kein Server ist
        if (this.adEventService.canSendImpression()) {
          this.adEventService.emitPopupBannerImpressionEvent(this.getEventMetadata()).subscribe();
        }
      });
  }

  public close() {
    this.listener();
    this.popupBanner = null;
  }

  public click() {
    // Click-Event speichern
    this.adEventService.emitPopupBannerClickEvent(this.getEventMetadata()).subscribe();
  }

  private getEventMetadata(): PopupBannerMetaData {
    const metaData: PopupBannerMetaData = this.adEventService.getMetaData() as PopupBannerMetaData;
    // @ts-ignore
    metaData.popupBannerId = this.popupBanner.id;
    return metaData;
  }
}
