import {Injectable} from '@angular/core';
import {AdsApiService} from './ads-api.service';
import {Observable} from 'rxjs';
import {PopupBanner} from '../../entities/ads/popup-banner';
import {HeadlineBlock} from '../../entities/ads/headline-block';
import {VisualAd} from '@kpro-software/horsetelex-ads';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(private readonly apiService: AdsApiService) {
  }

  public getActiveVisualAds(adspaceId: string): Observable<Array<VisualAd>> {
    return this.apiService.get<Array<VisualAd>>('ads/active-visuals/by/ad-space?id=' + adspaceId);
  }

  public getActivePopupBanner() {
    const timestamp = Date.now();
    return this.apiService.get<PopupBanner>('popup-banners/find-by-timestamp', {timestamp, page: 'RESULTS'});
  }

  public getHeadlineBlock(timestamp: number): Observable<HeadlineBlock> {
    return this.apiService.get<HeadlineBlock>('headline-blocks/find-by-timestamp', {timestamp, page: 'RESULTS'});
  }
}
