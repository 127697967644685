import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Country} from '../../entities/results/country';
import {LocaleService} from '../locale.service';
import {Observable, ReplaySubject} from 'rxjs';
import {ApiService} from '../api/api.service';
import {TourneySearchRequest} from '../../data/requests/tourney-search-request';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {


  private countries = new ReplaySubject<Country[]>(1);
  private isLoading = false;

  constructor(private readonly api: ApiService,
              private readonly localeService: LocaleService) {
  }

  public loadCountries(): Observable<Country[]> {
    return this.api.get<Country[]>('countries/options')
      .pipe(map((countries: Country[]) => {
        // Land mit der ID "0" entfernen
        const noSelectionCountry = countries.find(c => c.id === 0);

        if (noSelectionCountry) {
          countries.splice(countries.indexOf(noSelectionCountry), 1);
        }

        return countries;
      }));
  }

  public getCountries(): Observable<Country[]> {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loadCountries().subscribe(countries => this.countries.next(countries));
    }
    return this.countries.asObservable();
  }

  public getCountriesWithTourneys(): Observable<Country[]> {
    return this.api.get('countries/get-countries-with-tourneys')
  }

  public getCurrentCountryId(): Observable<number> {
    let locale = this.localeService.localeId;
    if (locale === 'en' || locale === 'en-US') {
      locale = 'gb';
    }
    return this.countries
      .pipe(map((countries: Country[]) => countries.find(country => country.codea.toLowerCase() === locale.toLowerCase())?.id ?? 2159));
  }

  public getCountriesForTourneySearch(filter: TourneySearchRequest): Observable<Country[]> {
    return this.api.post("countries/get-countries-for-tourney-search", filter)
  }
}
