<div class="modal-header">
  <h5 class="mb-0" i18n="cookie consent | Cookie settings @@cookieSettings">Cookie-Einstellungen</h5>
  <div class="cross" (click)="cancel()"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
  <p i18n="cookie consent | To use this feature we need the following permissions: @@cookieConsentNeeded">
    Um dieses Feature zu nutzen benötigen wir folgende Berechtigungen:
  </p>
  <h5>{{consent.title}}</h5>
  <p>{{consent.description}}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="cancel()" i18n="Cancel @@cancel">Abbrechen</button>
  <button class="btn btn-success" (click)="save()" i18n="Accept @@accept">Akzeptieren</button>
</div>
