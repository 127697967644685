import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input()
  public title!: string;

  @Input()
  public message!: string;

  public applyChoice(choice: boolean) {
    this.activeModal.close(choice);
  }

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

}
