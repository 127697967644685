import {AbstractControl, ValidatorFn} from '@angular/forms';

// tslint:disable:no-conditional-assignment
export function fullTextValidator(minLength = 2): ValidatorFn {
  return ((control: AbstractControl): { [key: string]: any } | null => {
    let value: string = control.value;

    if (!value || value.length === 0) {
      return null;
    }

    // Zeichen entfernen, die für die Volltextsuche verwendet werden
    let match;
    const searchCharacters = RegExp(/[+,*]/);
    while ((match = searchCharacters.exec(value))) {
      value = value.replace(searchCharacters, '');
    }

    // Bindestriche durch Leerzeichen ersetzen
    const spaces = RegExp(/-/);
    while ((match = spaces.exec(value))) {
      value = value.replace(spaces, ' ');
    }

    value = value.trim().toLowerCase();

    return value.length >= minLength && value !== 'xx' ? null : {fullText: {min: minLength, current: value.length}};
  });
}
