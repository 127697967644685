import {Component, Input, OnInit} from '@angular/core';
import {LinkService} from '../../../../services/link.service';

@Component({
  selector: 'app-pedigree-horse-link',
  templateUrl: './pedigree-horse-link.component.html',
  styleUrls: ['./pedigree-horse-link.component.scss']
})
export class PedigreeHorseLinkComponent implements OnInit {
  @Input()
  public horseId!: number | undefined;

  @Input()
  public horseName!: string | undefined;

  @Input()
  public alias1: string | null | undefined;

  @Input()
  public alias2: string | null | undefined;

  @Input()
  public alias3: string | null | undefined;

  @Input()
  public onEmptyText = '-';

  @Input()
  public target: '_blank' | '_self' = '_blank';

  public url: string | null = null;
  public tooltip: string | null = null;

  constructor(private readonly linkService: LinkService) {
  }

  ngOnInit(): void {
    if (this.horseId) {
      this.url = this.linkService.getPedigreeLink(this.horseId);
    }

    this.tooltip = this.getTooltip();
  }

  private getTooltip(): string | null {
    const aliases = [];

    if (this.alias1) {
      aliases.push(this.alias1)
    }

    if (this.alias2) {
      aliases.push(this.alias2)
    }

    if (this.alias3) {
      aliases.push(this.alias3)
    }

    if (aliases.length > 0) {
      return this.horseName + ' (' + aliases.join(', ') + ')';
    } else {
      return this.horseName ?? null;
    }
  }
}
