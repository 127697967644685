import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {LoginStateService} from './login-state.service';
import {ActivatedRoute, Router, UrlTree} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LoginResponse, LoginService, LoginState, TargetService} from '@kpro-software/users-lib';
import {Routes} from '../enums/routes';

@Injectable({
  providedIn: 'root'
})
export class SignInService {

  constructor(private readonly loginService: LoginService,
              private readonly session: SessionService,
              private readonly loginStateService: LoginStateService,
              private readonly router: Router,
              private readonly route: ActivatedRoute) {
  }

  public login(username: string, password: string, stayLoggedIn = false, redirectUrl:string | UrlTree = Routes.HOME): Observable<LoginState | null> {
    // @ts-ignore
    return this.loginService.generateAuthenticationToken(username, password, TargetService.RESULTS)
      .pipe(
        // @ts-ignore
        switchMap((response: LoginResponse) => this.processLogin(response, stayLoggedIn, redirectUrl))
      );
  }

  public processLogin(loginResponse: LoginResponse, stayLoggedIn = false, redirectUrl: string | UrlTree = Routes.HOME): Observable<LoginState | null> {
    this.session.removeCookies();
    this.session.saveAll(loginResponse, stayLoggedIn);
    return this.loginStateService.loadLoginState()
      .pipe(
        tap((loginState: LoginState | null) => {
          this.session.loginState = loginState;

          const urlParam = this.route.snapshot.queryParams['url'];
          this.router.navigateByUrl(urlParam ?? redirectUrl);
        })
      )
  }
}
