import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-funnel',
  templateUrl: './subscribe-funnel.component.html',
  styleUrls: ['./subscribe-funnel.component.scss']
})
export class SubscribeFunnelComponent implements OnInit {

  @Input()
  public activeStep!: 0 | 1;

  @Input()
  public firstStepName!: string;

  @Input()
  public secondStepName!: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
