<div class="modal-header">
  <h5 class="mb-0" i18n="cookie consent | Cookie settings @@cookieSettings">Cookie-Einstellungen</h5>
  <div class="cross" (click)="cancel()"><i class="fa fa-times"></i></div>
</div>
<div class="modal-body">
  <p i18n="cookie consent | cookie settings headline @@cookieSettingsHeadline">
    Sie können ihre Einstellungen jederzeit über "Cookie-Einstellungen" oben auf der Seite ändern.
    Genaue Informationen finden Sie in unserer Datenschutzerklärung.
  </p>
  <div class="categories" [formGroup]="form">
    <div class="category" *ngFor="let category of categories;">
      <div class="checkbox">
        <input type="checkbox" [id]="category.category.valueOf()"
               [formControlName]="CookieConsentCategory[category.category]"
               [attr.disabled]="category.deactivatable ? null : true">
      </div>
      <label [for]="category.category.valueOf()" class="text">
        <span>{{category.title}}</span>
        <span>{{category.description}}</span>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="cancel()" i18n="Cancel @@cancel">Abbrechen</button>
  <button class="btn btn-success" (click)="save()" i18n="Save @@save">Speichern</button>
</div>
