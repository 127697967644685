import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'rankings',
        loadChildren: () => import('./modules/money-rankings/money-rankings.module').then(m => m.MoneyRankingsModule)
      },
      {
        path: 'pages/view',
        loadChildren: () => import('./modules/content-pages/content-pages.module').then(m => m.ContentPagesModule)
      },
      {
        path: 'ipv-rankings',
        loadChildren: () => import('./modules/iv/iv.module').then(m => m.IvModule)
      },
      {
        path: '',
        loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule)
      },
      {
        path: '',
        loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule),
      },
      {
        path: '',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
      },
    ]
  },
  {
    path: 'ipvRankings/index',
    redirectTo: 'ipv-rankings',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabled'
})
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
