import {Injectable} from '@angular/core';
import {PedigreeApiService} from './pedigree-api.service';
import {map, Observable} from 'rxjs';
import {PedigreeStudbook} from '../../data/responses/pedigree/pedigree-studbook';
import {PedigreePaginatedResponse} from '../../data/responses/pedigree/pedigree-paginated-response';

@Injectable({
  providedIn: 'root'
})
export class PedigreeStudbooksService {

  constructor(private api: PedigreeApiService) {
  }

  public search(searchWord: string, page = 1, itemsPerPage = 5): Observable<PedigreePaginatedResponse<PedigreeStudbook>> {
    return this.api.post<PedigreePaginatedResponse<PedigreeStudbook>>('studbooks/search', {
      searchWord,
      itemsPerPage,
      page
    });
  }

  public find(id: number): Observable<PedigreeStudbook> {
    return this.api.post<PedigreeStudbook>('studbooks/find', {id});
  }

  public getSuggestions = (searchWord: string): Observable<PedigreeStudbook[]> => {
    return this.search(searchWord).pipe(map((response: PedigreePaginatedResponse<PedigreeStudbook>) => response.data));
  };

  public getStudbook = (id: number): Observable<PedigreeStudbook> => {
    return this.find(id);
  };
}
