import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeaheadComponent),
      multi: true,
    }
  ],
})
export class TypeaheadComponent implements OnInit, ControlValueAccessor {

  constructor() {
  }

  @Input()
  public inputId: string | null = null;

  @Input()
  public isInvalid = false;

  @Input()
  public placeholder = '';

  @Input()
  public displayFieldName: string | null = null;

  @Input()
  public idFieldName: string | null = null;

  @Input()
  public writeValueAsObject = false;

  @Input()
  public suggestionHandler!: (input: string) => Observable<object[]>;

  @Input()
  public findHandler!: (id: any) => Observable<any>;

  @Input()
  public debounceTime = 300;

  @Input()
  public minSearchWordLength = 1;

  @Input()
  public formControlClass = 'form-control';

  public formControl = new FormControl();
  public showSuggestion = false;
  public suggestions: any[] = [];

  public value = null;
  public disabled = false;
  public selectedItem = null;

  public onChange = (value: any) => {
  };
  public onTouched = () => {
  };

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(input => {
      if (input && this.selectedItem && this.selectedItem !== input) {
        this.formControl.patchValue(null);
        this.selectedItem = null;
      }
    });
  }

  search = (term: Observable<string>) => {
    return term.pipe(
      distinctUntilChanged(),
      debounceTime(this.debounceTime),
      filter((value: string) => (!!value && value.trim().length >= this.minSearchWordLength)),
      switchMap((input: string) => {
        return this.suggestionHandler(input.trim());
      })
    );
  };

  suggestionFormatter = (suggestion: any) => {
    if (this.displayFieldName && suggestion[this.displayFieldName]) {
      return suggestion[this.displayFieldName];
    } else {
      return suggestion;
    }
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.selectedItem = this.value;

    if (value && this.idFieldName && value[this.idFieldName]) {
      this.value = !this.writeValueAsObject && this.idFieldName ? value[this.idFieldName] : value;
      this.formControl.patchValue(this.displayFieldName ? value[this.displayFieldName] : value);

    } else if (value && this.idFieldName) {
      this.value = value;
      this.findHandler(value).subscribe(entry => {
        this.selectedItem = null;
        this.formControl.patchValue(this.displayFieldName ? entry[this.displayFieldName] : entry);
        this.selectedItem = entry;
      });

    } else if (value) {
      this.value = value;
      this.formControl.patchValue(value);

    } else {
      this.value = null;
      this.formControl.patchValue(null);
    }
  }

  select(event: NgbTypeaheadSelectItemEvent<any>) {
    const selectedItem = event.item;

    if (this.writeValueAsObject || !this.idFieldName) {
      this.value = selectedItem;
    } else {
      this.value = selectedItem[this.idFieldName];
    }

    this.selectedItem = selectedItem;
    this.onChange(this.value);
  }
}
