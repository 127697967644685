import {Pipe, PipeTransform} from '@angular/core';
import {IpvType} from '../../../../enums/ipv/ipv-type';

@Pipe({
  name: 'ipvType'
})
export class IpvTypePipe implements PipeTransform {

  transform(type: string | null): string | null {
    switch (type) {
      case IpvType.DYNAMIC_ISV:
        return $localize`:@@ipvTypeDynamicIsv:Dynamic ISV`;
      case IpvType.DYNAMIC_IPV:
        return $localize`:@@ipvTypeDynamicIpv:Dynamic IPV`;
      case IpvType.ISV_RANKING_LAST_YEAR:
        return $localize`:@@ipvTypeIsvRankingCurrentYear:ISV ranking` + ' ' + this.getLastYear();
      case IpvType.IPV_INDEX_LAST_YEAR:
        return $localize`:@@ipvTypeIpvIndexCurrentYear:IPV index` + ' ' + this.getLastYear();
      case IpvType.MY_ISV:
        return $localize`:@@ipvTypeMyIsv:My ISV`;
      case IpvType.MY_IPV:
        return $localize`:@@ipvTypeMyIpv:My IPV`;
      default:
        return type;
    }
  }

  private getLastYear(){
    return new Date().getFullYear() - 1;
  }
}
