<div>
  <app-ad-banner-container [id]="'RESULTS_RIGHT_0'" class="mt-2"></app-ad-banner-container>
</div>
<div>
  <app-ad-banner-container [id]="'RESULTS_RIGHT_1'" class="mt-2"></app-ad-banner-container>
</div>
<div>
  <app-ad-banner-container [id]="'RESULTS_RIGHT_2'" class="mt-2"></app-ad-banner-container>
</div>
<div>
  <app-ad-banner-container [id]="'RESULTS_RIGHT_3'"></app-ad-banner-container>
</div>
