<div id="header-search" class="header-search">
  <div>
    <a [routerLink]="Routes.HOME">
      <img src="/public/images/logo-red-small.png" class="ht-header-logo" alt="Logo">
    </a>
  </div>
  <div>
    <div class="input-group">
      <input class="form-control"
             [formControl]="searchForm"
             placeholder="Stadt/Show"
             i18n-placeholder="default layout header | City/Show @@cityShow"
             (keydown.enter)="search()">
      <button class="btn btn-horsetelex-results" [disabled]="searchForm.invalid" (click)="search()">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div class="float-end">
      <div *ngIf="isLoading">
          <span>
            <i class="p-2 fas fa-spinner fa-spin"></i>
          </span>
        <span i18n="default layout header | entries are loaded layout@@headerEntriesAreLoaded">
            Einträge werden geladen
          </span>
      </div>
    </div>
  </div>
</div>
<div class="header-navigation clearfix">
  <div class="menu">
    <div [ngClass]="{'active-link': isActive(Routes.HOME)}">
      <a [routerLink]="Routes.HOME" i18n="default layout header | home @@headerHome">
        Home
      </a>
    </div>
    <div [ngClass]="{'active-link': isActive(Routes.CALENDAR)}">
      <a class="click-link" i18n="default layout header | calendar @@headerCalendar" [routerLink]="Routes.CALENDAR">
        Kalender
      </a>
    </div>
    <div [ngClass]="{'active-link': isActive(Routes.RESULTS)}">
      <a i18n="default layout header | results @@headerResults" [routerLink]="Routes.RESULTS">
        Ergebnisse
      </a>
    </div>
    <div [ngClass]="{'active-link': isActive(Routes.RANKINGS)}">
      <a i18n="default layout header | moneyRanking @@moneyRanking" [routerLink]="Routes.RANKINGS">
        Gewinnsummen
      </a>
    </div>
    <div [ngClass]="{'active-link': isActive(Routes.ISV_IPV)}">
      <a i18n="default layout header | ISV/IPV @@headerISV/IPV" [routerLink]="Routes.ISV_IPV">
        ISV/IPV
      </a>
    </div>
    <div [ngClass]="{'active-link': isActive(Routes.ALERTS)}">
      <a i18n="default layout header | Alerts @@headerAlerts" [routerLink]="Routes.ALERTS">
        Alerts
      </a>
    </div>
    <div [ngClass]="{'active-link': isActive(Routes.ALERTS_RESULTS)}">
      <a i18n="default layout header | My results @@headerMyResults" [routerLink]="Routes.ALERTS_RESULTS">
        Meine Ergebnisse
      </a>
    </div>
  </div>
  <div class="header-buttons">
    <div>
      <a [routerLink]="Routes.ALERTS_CREATE" class="btn btn-secondary"
         i18n="default layout header | Create alert @@layoutCreateAlert">
        Alert erstellen
      </a>
    </div>
    <div *ngIf="!loginState?.membership">
      <a [routerLink]="Routes.SUBSCRIBE" class="btn btn-subscribe"
         i18n="default layout header | Become member @@layoutBecomeMember">
        Mitglied werden
      </a>
    </div>
  </div>
</div>
