import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {from} from 'rxjs';
import {ConfirmComponent} from '../modules/shared/ui/confirm/confirm.component';
import {CheckComponent} from '../modules/shared/ui/check/check.component';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(private modalService: NgbModal) {
  }

  public modal(component: any, data: { [key: string]: any } = {}, options: NgbModalOptions = {
    centered: true,
    keyboard: true,
    backdrop: 'static'
  }) {
    const modalRef = this.modalService.open(component, options);

    Object.keys(data).forEach(key => {
      // Alle Werte setzen
      modalRef.componentInstance[key] = data[key];
    });

    // Result als Observable zurückgeben
    return {
      ref: modalRef,
      result: from(modalRef.result)
    };
  }

  public show(title: string, message: string, options: NgbModalOptions = {centered: true}) {
    const modal = this.modal(ConfirmComponent, {
      title,
      message
    }, options);

    return modal;
  }

  public confirm(title: string, message: string, options: NgbModalOptions = {centered: true}) {
    const modal = this.modal(CheckComponent, {
      title,
      message
    }, options);

    return modal;
  }

  // /**
  //  *
  //  * @param Optionale Inputs: data {sex, headline, text, isSelect, itemsPerPage, searchProperties: SimpleHorseSearchRequest}
  //  */
  // public searchHorse(data: { [key: string]: any }) {
  //   const options: NgbModalOptions = {
  //     centered: true,
  //     keyboard: true,
  //     backdrop: 'static',
  //     ariaLabelledBy: 'search-horse',
  //     size: 'xl',
  //   };
  //
  //   const modalRef = this.modalService.open(SearchComponent, options);
  //
  //   Object.keys(data).forEach(key => {
  //     // Alle Werte setzen
  //     modalRef.componentInstance[key] = data[key];
  //   });
  //
  //   // Result als Observable zurückgeben
  //   return {
  //     ref: modalRef,
  //     result: from(modalRef.result)
  //   };
  // }


  // public extendedHorseSearch(data: { [key: string]: any }) {
  //   const options: NgbModalOptions = {
  //     centered: true,
  //     keyboard: true,
  //     backdrop: 'static',
  //     size: 'xl'
  //   };
  //
  //   const modalRef = this.modalService.open(ExtendedSearchComponent, options);
  //
  //   Object.keys(data).forEach(key => {
  //     // Alle Werte setzen
  //     modalRef.componentInstance[key] = data[key];
  //   });
  //
  //   // Result als Observable zurückgeben
  //   return {
  //     ref: modalRef,
  //     result: from(modalRef.result)
  //   };
  // }
}
