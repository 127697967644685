import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NavigationBarComponent} from './layouts/default-layout/navigation-bar/navigation-bar.component';
import {HeaderComponent} from './layouts/default-layout/header/header.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FooterComponent} from './layouts/default-layout/footer/footer.component';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PopupBannerComponent} from './layouts/ui/popup-banner/popup-banner.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginStateService} from './services/login-state.service';
import {SessionService} from './services/session.service';
import {initLoginState} from './app-initializer';
import {TokenInterceptor} from './token.interceptor';
import {SingleSignOnService, UserLibAccessTokenProviderService, UsersLibModule} from '@kpro-software/users-lib';
import {environment} from '../environments/environment';
import {MyHtLayoutComponent} from './layouts/my-ht-layout/my-ht-layout.component';
import {
  ExtendedCookieConsentModalComponent
} from './layouts/ui/cookie-consent/extended-cookie-consent-modal/extended-cookie-consent-modal.component';
import {
  SingleCookieConsentModalComponent
} from './layouts/ui/cookie-consent/single-cookie-consent-modal/single-cookie-consent-modal.component';
import {
  CookieConsentBannerComponent
} from './layouts/ui/cookie-consent/cookie-consent-banner/cookie-consent-banner.component';
import {ConsentService} from './services/consent.service';
import {SharedModule} from './modules/shared/shared.module';
import {RightSidebarComponent} from './layouts/default-layout/right-sidebar/right-sidebar.component';


registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    NavigationBarComponent,
    RightSidebarComponent,
    HeaderComponent,
    FooterComponent,
    MyHtLayoutComponent,
    PopupBannerComponent,
    CookieConsentBannerComponent,
    ExtendedCookieConsentModalComponent,
    SingleCookieConsentModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserTransferStateModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    UsersLibModule.forRoot({
      userServiceEndpoint: environment.userServiceEndpoint,
      languageUrls: environment.languageUrls
    }),
    SharedModule
  ],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLoginState,
      multi: true,
      deps: [
        LoginStateService,
        SessionService,
        SingleSignOnService,
        ConsentService,
        UserLibAccessTokenProviderService
      ]
    }
  ],
  exports: [
    PopupBannerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
