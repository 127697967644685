import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Languages} from '../enums/languages.enum';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  // tslint:disable-next-line:variable-name
  private _localeId;

  constructor(@Inject(LOCALE_ID) localeId: string) {

    this._localeId = localeId.toLowerCase();
  }

  get localeId(): string {
    return this._localeId;
  }

  get numericLocaleId(): number {
    let numericLocaleId;

    switch (this._localeId) {
      case 'nl':
        numericLocaleId = Languages.nl;
        break;
      case 'de':
        numericLocaleId = Languages.de;
        break;
      case 'fr':
        numericLocaleId = Languages.fr;
        break;
      default:
        numericLocaleId = Languages.en;
    }

    return numericLocaleId;
  }

  public getCurrentLanguageUrl(): string {
    return this.getLanguageUrl(this._localeId);
  }

  public isLanguageSupported(localeId: number): boolean {
    let isLanguageSupported = false;
    Object.keys(Languages).forEach(key => {
      // @ts-ignore
      if (Languages[key] === localeId) {
        isLanguageSupported = true;
      }
    });
    return isLanguageSupported;
  }

  public getDateFormat() : string{
    const locale = this.numericLocaleId;

    switch (locale) {
      case Languages.de:
        return 'DD.MM.YYYY';
      case Languages.nl:
        return 'DD-MM-YYYY';
      default:
        return 'DD/MM/YYYY';
    }
  }

  public getLanguageUrl(locale: string): string {
    switch (locale) {
      case 'nl':
        return environment.languageUrls.nl;
      case 'de':
        return environment.languageUrls.de;
      case 'fr':
        return environment.languageUrls.fr;
      default:
        return environment.languageUrls.en;
    }
  }

  public getPedigreeUrl(): string{
      switch (this._localeId) {
        case 'nl':
          return environment.pedigreeUrls.nl;
        case 'de':
          return environment.pedigreeUrls.de;
        case 'fr':
          return environment.pedigreeUrls.fr;
        default:
          return environment.pedigreeUrls.en;
      }
    }
}
