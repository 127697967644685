export const environment = {
  production: true,
  apiEndpoint: 'https://www.horsetelex-results.com/results/',
  userServiceEndpoint: 'https://www.horsetelex-results.com/user-service/',
  horsetelexPedigree: 'https://horsetelex.de/',
  cmsEndpoint: 'https://www.horsetelex-results.com/cms/api/',
  adsEndpoint: 'https://www.horsetelex-results.com/comms/',
  pedigreeEndpoint: 'https://www.horsetelex-results.com/pedigree/',
  horsesSearchEndpoint: 'https://www.horsetelex-results.com/search/',
  languageUrls: {
    de: 'https://www.horsetelex-results.de/',
    en: 'https://www.horsetelex-results.com/',
    fr: 'https://www.horsetelex-results.fr/',
    nl: 'https://www.horsetelex-results.nl/'
  },
  staticEndpoint: 'https://static.horsetelex.com/',
  pedigreeUrls: {
    de: 'https://www.horsetelex.de/',
    en: 'https://www.horsetelex.com/',
    fr: 'https://www.horsetelex.fr/',
    nl: 'https://www.horsetelex.nl/'
  },
  salesUrl: 'https://horsetelexsales.com/',
  cookieConsentStorageDuration: 90, // Angabe in Tagen
  googleAnalyticsTrackingCode: 'test',
};
