import {Component, OnInit} from '@angular/core';
import {Routes} from '../../../enums/routes';
import {SimplePages} from '../../../enums/simple-pages';
import {ConsentService} from '../../../services/consent.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public Routes = Routes;
  public SimplePages = SimplePages;

  constructor(private readonly consentService: ConsentService) {
  }

  ngOnInit(): void {
  }

  public openCookieSettings() {
    this.consentService.changeSettings();
  }

  public getCurrentYear() {
    return (new Date).getFullYear();
  }
}
