<div class="row">
  <div class="col-7">
    <div class="heading" i18n="Register @@registerRegister">
      Anmelden
    </div>
    <div>
      <app-registration-form [focus]="fragment === 'register'" [redirectUrl]="redirectUrl"></app-registration-form>
    </div>
  </div>
  <div class="col">
    <div>
      <div class="heading" i18n="Einloggen @@login">
        Login
      </div>
      <app-login [focus]="fragment !== 'register'" [redirectUrl]="redirectUrl"></app-login>
    </div>
    <div class="mt-5" *ngIf="displayResetPasswordForm">
      <div class="heading" i18n="register | Forgot your password? @@registerForgotYourPassword">
        Passwort Vergessen?
      </div>
      <app-reset-password-form></app-reset-password-form>
    </div>
  </div>
</div>
