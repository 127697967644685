import {Directive, ElementRef, Input} from '@angular/core';
import {StaticService} from '../../../services/static.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[staticSrc]'
})
export class StaticSrcDirective {

  @Input()
  set staticSrc(src: string) {
    this.setSrc(src);
  }

  constructor(private el: ElementRef, private staticService: StaticService) {
  }

  private setSrc(src: string) {
    if (src){
      this.el.nativeElement.src = this.staticService.getImageUrl(src);
    }
  }
}
