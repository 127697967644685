<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('CROSS')"></button>
</div>
<div class="modal-body" style="white-space: pre-wrap">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="applyChoice(true)" i18n="check | accept check@@accept">Akzeptieren</button>
  <button type="button" class="btn btn-outline-dark" (click)="applyChoice(false)" i18n="check | reject check@@reject">Ablehnen</button>
</div>
