import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MessageBoxService} from '../../../../services/message-box.service';
import {LoginEvent} from '@kpro-software/users-lib';
import {TargetService} from '@kpro-software/users-lib';
import {SignInService} from '../../../../services/sign-in.service';
import {Routes} from '../../../../enums/routes';
import {RedirectService} from '../../../../services/redirect.service';
import {isPlatformBrowser} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {UserApiService} from '../../../../services/user/user-api.service';
import {AuthorizationTokenType} from '../../../../enums/authorization-token-type.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input()
  public redirectUrl?: Routes;

  @Input()
  public focus = false;

  public readonly targetService = TargetService.RESULTS;

  constructor(private readonly fb: FormBuilder,
              private api: UserApiService,
              private readonly messageBoxService: MessageBoxService,
              private readonly toast: ToastrService,
              private readonly redirectService: RedirectService,
              private readonly loginService: SignInService,
              private readonly route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const adminAuthToken = this.route.snapshot.queryParams['adminAuthToken'];
      const singleSignOnToken = this.route.snapshot.queryParams['singleSignOnToken'];

      if (adminAuthToken) {
        this.loginAdminAsUser(adminAuthToken);
      } else if (singleSignOnToken) {
        this.loginSingleSignOn(singleSignOnToken);
      }
    }
  }

  private loginAdminAsUser(adminAuthToken: string) {
    this.api.logout();
    this.loginService.login(AuthorizationTokenType.ADMIN_AUTH, adminAuthToken).subscribe(() => {
      this.toast.success($localize`:@@loginSuccess:Sie haben sich erfolgreich eingeloggt`);
    }, error => {
      this.toast.error($localize`:@@loginError:Login fehlgeschlagen`);
    });
  }

  private loginSingleSignOn(singleSignOnToken: string) {
    this.api.logout();

    const redirectUrl = this.route.snapshot.queryParams['redirectUrl'];

    this.loginService.login(AuthorizationTokenType.SINGLE_SIGN_ON, singleSignOnToken, false, redirectUrl)
      .subscribe(() => {
      }, error => {
        this.toast.error($localize`:@@loginError:Login fehlgeschlagen`);
      });
  }

  public onLogin(event: LoginEvent): void {
    this.toast.success($localize`:@@loginSuccess:Sie haben sich erfolgreich eingeloggt`);
    this.loginService.processLogin(event, event.stayLoggedIn, this.redirectUrl ?? this.redirectService.buildRedirect()).subscribe();
  }

  public onError(): void {
    this.messageBoxService.show(
      $localize`:@@loginError:Login fehlgeschlagen`,
      $localize`:@@loginErrorMessage:Bitte überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort`);
  }
}
