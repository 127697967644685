import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

  @Input()
  public title!: string;

  @Input()
  public message!: string;

  public applyChoice(choice: boolean) {
    this.activeModal.close(choice);
  }

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

}
